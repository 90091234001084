import React from 'react';
import {ArrowRightCircleIcon} from '@heroicons/react/20/solid';
import {useNavigate} from 'react-router-dom';
import classNames from 'classnames';

interface MethodLibraryTileProps {
  children: React.ReactNode;
  text: string;
  nav: string;
  className?: string;
}

export const HomeTile: React.FC<MethodLibraryTileProps> = ({children, text, nav, className = ''}) => {
  const navigate = useNavigate();
  return (
    <div
      className={classNames(
        "w-full flex-1 flex rounded-xl bg-white w-full min-w-[300px] h-[289px] p-6 justify-center flex-col gap-8 hover:mt-[-4px] hover:mb-[4px] hover:border-2 hover:border-helvetiablue hover:shadow-popblue transition-all font-['Inter'] group hover:cursor-pointer group relative",
        className
      )}
      onClick={() => {
        return navigate(nav);
      }}
      aria-hidden
    >
      <div className="object-contain max-w-[80%] max-h-[80%] m-auto">{children}</div>
      <div className="flex justify-center items-center w-full absolute bottom-2 left-0 px-4">
        <p className="text-2xl leading-[29px] font-black text-start whitespace-break-spaces">{text}</p>
        <ArrowRightCircleIcon className="w-[2em] ml-auto  invisible group-hover:visible" />
      </div>
    </div>
  );
};
