import {DragDropContext, DropResult} from '@hello-pangea/dnd';
import React, {useState} from 'react';
import produce from 'immer';
import _isEqual from 'lodash/isEqual';
import {WritableDraft} from 'immer/dist/internal';
import dayjs from 'dayjs';
import {ContentSave} from 'assets';
import {useLinkMiroBoardToWorkshop, useMethods, useMiroBoardFromWorkshop, useSelf} from 'services/query';
import classNames from 'classnames';
import {Button, FakeProgressLoader, InlineEdit, WarningModal} from 'components';
import ReactRouterPrompt from 'react-router-prompt';
import {useDocumentTitle} from 'hooks';
import {UserRole} from 'types';
import {
  AddMiroButton,
  AddMiroModal,
  EditorMenuButton,
  MethodLibrary,
  OpenMiroButton,
  RecipeFieldsModal,
  SessionTabs,
  WorkshopCreator,
  WorkshopForm,
  WorkshopOverview,
} from './components';
import {draggableIdToEditableMethod, getEmptySession, getWorkshopState, WorkshopModificationState} from './helpers';
import {useSessionTabs, useWorkshopCreator, useWorkshopForm} from './hooks';
import styles from './WorkshopsPage.module.scss';

const creatorDroppableId = 'creator';
const defaultTitle = 'New Workshop';

interface WorkshopTemplateProps {
  workshop?: Partial<ToolboxObject.Workshop>;

  handleSave?(
    title: string,
    sessions: ToolboxObject.WorkshopSession[],
    description: string,
    requirements: string[],
    benefits: string[],
    category: ToolboxObject.WorkshopCategory | null,
    teaser: string | null
  ): void;

  handleExportAsRecipe?(
    title: string,
    sessions: ToolboxObject.WorkshopSession[],
    description: string,
    requirements: string[],
    benefits: string[],
    category: ToolboxObject.WorkshopCategory | null,
    teaser: string | null
  ): void;

  handleDelete?(title: string): void;

  isMutationLoading?: boolean;
}

export const WorkshopTemplate: React.FC<WorkshopTemplateProps> = ({
  workshop = undefined,
  handleSave = undefined,
  handleExportAsRecipe = undefined,
  handleDelete = undefined,
  isMutationLoading = false,
}) => {
  useDocumentTitle('root');

  const initialWorkshop: ToolboxObject.WorkshopEditableState = {
    sessions: workshop?.sessions || [getEmptySession()],
    title: workshop?.title || defaultTitle,
    description: workshop?.description || '',
    requirements: workshop?.requirements || [],
    benefits: workshop?.benefits || [],
    category: workshop?.category || null,
    teaser: workshop?.teaser || null,
  };
  const [miroModalOpen, setMiroModalOpen] = React.useState(false);
  const [sessions, setSessions] = React.useState<ToolboxObject.WorkshopSession[]>(initialWorkshop.sessions);
  const [title, setTitle] = React.useState(initialWorkshop.title);
  const [description, setDescription] = React.useState(initialWorkshop.description);
  const [requirements, setRequirements] = React.useState(initialWorkshop.requirements);
  const [benefits, setBenefits] = React.useState(initialWorkshop.benefits);
  const [category, setCategory] = React.useState(initialWorkshop.category ?? null);
  const [teaser, setTeaser] = React.useState(initialWorkshop.teaser ?? null);
  const [activeSession, setActiveSession] = React.useState(0);
  const [originWorkshopData, setOriginWorkshopData] = React.useState<ToolboxObject.WorkshopEditableState>(initialWorkshop);
  const [categoryModal, setCategoryModal] = useState(false);

  const methods = useMethods();
  const self = useSelf();

  const {mutate: createMiroBoard, isLoading: isCreatingMiroBoard} = useMiroBoardFromWorkshop(workshop?.id ?? -1);
  const {mutate: linkMiroBoard} = useLinkMiroBoardToWorkshop(workshop?.id ?? -1);

  const onDragEnd = React.useCallback(
    (result: DropResult) => {
      const {destination, source, draggableId} = result;

      const deleteMethod = (draft: WritableDraft<ToolboxObject.WorkshopSession>[], sourceIndex: number) => {
        return draft[activeSession].editable_methods.splice(sourceIndex, 1)[0];
      };
      const addMethod = (draft: WritableDraft<ToolboxObject.WorkshopSession>[], destinationIndex: number, method: ToolboxObject.EditableMethod) => {
        draft[activeSession].editable_methods.splice(destinationIndex, 0, method);
      };

      const sortByOrder = (draft: WritableDraft<ToolboxObject.WorkshopSession>[]) => {
        draft[activeSession].editable_methods.forEach((item, i) => {
          item.order = i;
        });
      };

      if (result.type.startsWith('BENEFIT')) {
        if (!destination || source.droppableId !== destination.droppableId) {
          return;
        }
        setBenefits(
          produce(benefits, (draft) => {
            draft?.splice(source.index, 1);
            draft?.splice(destination.index, 0, benefits ? benefits[source.index] : '');
          })
        );
        return;
      }

      if (result.type.startsWith('REQUIREMENT')) {
        if (!destination || source.droppableId !== destination.droppableId) {
          return;
        }
        setRequirements(
          produce(requirements, (draft) => {
            draft?.splice(source.index, 1);
            draft?.splice(destination.index, 0, requirements ? requirements[source.index] : '');
          })
        );
        return;
      }

      if (result.type.startsWith('STEPS')) {
        if (!destination || source.droppableId !== destination.droppableId) {
          return;
        }
        const editableMethodIndex = +destination.droppableId.slice(destination.droppableId.lastIndexOf('-') + 1);
        setSessions(
          produce(sessions, (draft) => {
            draft[activeSession].editable_methods[editableMethodIndex].steps.splice(source.index, 1);
            draft[activeSession].editable_methods[editableMethodIndex].steps.splice(
              destination.index,
              0,
              sessions[activeSession].editable_methods[editableMethodIndex].steps[source.index]
            );
          })
        );
        return;
      }

      // Workshop method has been dragged
      if (source.droppableId === creatorDroppableId) {
        // Workshop method has been dragged out
        if (!destination) {
          setSessions(
            produce(sessions, (draft) => {
              deleteMethod(draft, source.index);
            })
          );
          // Workshop method location has been changed
        } else if (destination.droppableId === creatorDroppableId) {
          setSessions(
            produce(sessions, (draft) => {
              const method = deleteMethod(draft, source.index);
              addMethod(draft, destination.index, method);
              sortByOrder(draft);
            })
          );
        }
        // Library method has been dragged to Workshop methods
      } else if (destination?.droppableId === creatorDroppableId) {
        setSessions(
          produce(sessions, (draft) => {
            addMethod(draft, destination.index, draggableIdToEditableMethod(methods.data || [], draggableId));
            sortByOrder(draft);
          })
        );
      }
    },
    [sessions, activeSession, methods]
  );

  const editableMethods = React.useMemo(() => {
    if (activeSession === -1) {
      return null;
    }
    return sessions[activeSession].editable_methods;
  }, [sessions, activeSession]);

  const startDate = React.useMemo(() => {
    if (activeSession === -1) {
      return null;
    }
    return dayjs(sessions[activeSession].start);
  }, [sessions, activeSession]);

  const modificationState: WorkshopModificationState = {sessions, setSessions, activeSession, setActiveSession};
  const sessionTabsData = useSessionTabs(modificationState);
  const workshopFormData = useWorkshopForm(modificationState);
  const workshopCreatorDate = useWorkshopCreator(modificationState);

  const hasUnsavedChanges = React.useMemo(() => {
    return (
      !isMutationLoading &&
      !!originWorkshopData &&
      !_isEqual(getWorkshopState(originWorkshopData), {title, sessions, description, requirements, benefits, category, teaser})
    );
  }, [sessions, title, description, originWorkshopData, isMutationLoading, requirements, benefits, category, teaser]);

  if (isCreatingMiroBoard) {
    return (
      <div className="w-full flex-col justify-center items-center text-center pt-14 gap-8">
        <p className="font-bold text-xl pb-8">Creating Miro Board</p>
        <FakeProgressLoader loading={isCreatingMiroBoard} />
      </div>
    );
  }

  return (
    <div>
      <ReactRouterPrompt when={hasUnsavedChanges}>
        {({onConfirm, onCancel}) => {
          return (
            <WarningModal
              isOpen
              handleClose={onCancel}
              handleConfirm={onConfirm}
              title="Unsaved Workshop"
              confirmButtonTitle="Yes, I am sure"
              description="Are you sure you want to leave without saving your Workshop? All changes will be lost"
            />
          );
        }}
      </ReactRouterPrompt>
      {
        // TODO: temporary disclaimer
        workshop?.is_recipe && (
          <div className="absolute w-full bg-deletered h-8 text-center text-white items-center justify-center flex z-20">You are editing a public recipe</div>
        )
      }
      <div className="flex flex-col md:flex-row items-stretch gap-4 md:gap-0 md:h-full w-full">
        <AddMiroModal
          isOpen={miroModalOpen}
          onClose={() => setMiroModalOpen(false)}
          hasMiro={!!self.data?.miroAccess}
          createMiroBoard={createMiroBoard}
          linkMiroBoard={linkMiroBoard}
        />
        <RecipeFieldsModal
          isOpen={categoryModal}
          onClose={() => setCategoryModal(false)}
          exportAsRecipe={(selectedCategory: ToolboxObject.WorkshopCategory | null, newTeaser: string | null) => {
            if (handleExportAsRecipe) {
              handleExportAsRecipe(title, sessions, description ?? '', requirements ?? [], benefits ?? [], selectedCategory, newTeaser);
            }
          }}
        />
        <DragDropContext onDragEnd={onDragEnd}>
          <div
            className={classNames(styles.noScrollbar, 'flex flex-col gap-4 grow basis-[60%] bg-white p-6 sm:p-12 md:h-[calc(100vh-64px)] md:overflow-y-auto')}
          >
            <div className="flex gap-2 justify-between flex-wrap sm:flex-nowrap items-center">
              <InlineEdit className="text-2xl font-extrabold text-gray-900 w-full" value={title} setValue={setTitle} />
              <div className="flex gap-2 flex-wrap sm:flex-nowrap">
                {!workshop?.is_recipe &&
                  self.data?.role &&
                  [UserRole.ADMIN.toString(), UserRole.MIRO.toString()].includes(self.data.role) &&
                  !hasUnsavedChanges &&
                  !workshop?.miro_href && <AddMiroButton onClick={() => setMiroModalOpen(true)} />}
                {!workshop?.is_recipe &&
                  self.data?.role &&
                  [UserRole.ADMIN.toString(), UserRole.MIRO.toString()].includes(self.data.role) &&
                  !hasUnsavedChanges &&
                  workshop?.miro_href && <OpenMiroButton linkMiroBoard={linkMiroBoard} href={workshop?.miro_href ?? ''} />}
                <EditorMenuButton
                  handleDelete={() => {
                    if (handleDelete) handleDelete(title);
                  }}
                  showExportRecipe={!!(self.data?.role === UserRole.ADMIN && !hasUnsavedChanges && handleExportAsRecipe && !workshop?.is_recipe)}
                  handleExportRecipe={() => setCategoryModal(true)}
                />
                {handleSave && (
                  <Button
                    onClick={() => {
                      handleSave(title, sessions, description ?? '', requirements ?? [], benefits ?? [], category ?? null, teaser ?? null);
                      setOriginWorkshopData({title, sessions, description, benefits, requirements, category, teaser});
                    }}
                    className="px-0 w-[44px] h-[44px]"
                  >
                    <ContentSave fill="white" />
                  </Button>
                )}
              </div>
            </div>
            <SessionTabs {...sessionTabsData} />
            {editableMethods !== null ? (
              <>
                <WorkshopForm {...workshopFormData} editableMethods={editableMethods} startDate={startDate} />
                <WorkshopCreator {...workshopCreatorDate} editableMethods={editableMethods} droppableId={creatorDroppableId} startDate={startDate} />
              </>
            ) : (
              <WorkshopOverview
                description={description ?? ''}
                setDescription={setDescription}
                benefits={benefits ?? []}
                setBenefits={setBenefits}
                requirements={requirements ?? []}
                setRequirements={setRequirements}
                setCategory={workshop?.is_recipe ? setCategory : undefined}
                category={category}
                setTeaser={workshop?.is_recipe ? setTeaser : undefined}
                teaser={teaser}
              />
            )}
          </div>
          <MethodLibrary isLoading={methods.isLoading} methods={methods.data || []} />
        </DragDropContext>
      </div>
    </div>
  );
};
