import React, {useRef} from 'react';
import classNames from 'classnames';
import {Cross} from '../../assets';

interface ButtonProps {
  className?: string;
  query: string | undefined;
  setQuery: any;
}

export const SearchInput: React.FC<ButtonProps> = ({query, className = '', setQuery}) => {
  const inputRef = useRef(null);
  return (
    <div className={classNames(className, 'flex items-center justify-center text-center bottom-2 ')}>
      <input
        type="text"
        ref={inputRef}
        placeholder="Search"
        value={query}
        className="relative bg-white h-8 pl-4 pr-8 w-full sm:w-auto rounded-lg text-sm focus:outline-black border border-gray-200"
        onChange={(e) => {
          setQuery(e.target.value);
        }}
      />
      {!!query?.length && (
        <button
          type="button"
          className="flex justify-center items-center p-1 h-6 aspect-square ml-[-28px] z-10 rounded-full hover:bg-gray-100 text-black/[0.6] hover:text-black"
          onClick={() => {
            setQuery('');
            if (inputRef.current) {
              // @ts-ignore
              inputRef.current.focus();
            }
          }}
        >
          <Cross />
        </button>
      )}
    </div>
  );
};
