import React, {useMemo, useState} from 'react';
import {Disclosure} from '@headlessui/react';
import {Bars3Icon, XMarkIcon} from '@heroicons/react/24/outline';
import {NavLink, useNavigate} from 'react-router-dom';
import {useDisconnectMiro, useSelf} from 'services/query';
import {useQueryClient} from '@tanstack/react-query';
import {Menu} from 'components';
import {UserRole} from 'types';
import {RoutePath} from '../../Router';
import {logout} from '../../services';
import {CompanyLogoIcon, CompanyLogoLong} from '../../assets';
import {InviteModal} from '../../components/InviteModal';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const HeaderComponent: React.FC = () => {
  const {data: self} = useSelf();
  const {mutate: disconnectMiro} = useDisconnectMiro();
  const [showInviteModal, setShowInviteModal] = useState(false);
  const navigation = [
    {name: 'Home', href: RoutePath.root},
    {name: 'How-To', href: RoutePath.howto},
    {name: 'Library', href: RoutePath.library},
    {name: 'Workshops', href: RoutePath.workshopLibrary},
    ...(self?.role === UserRole.ADMIN ? [{name: 'Admin Dashboard', href: RoutePath.adminDashboard}] : []),
  ];
  const navigate = useNavigate();
  const qc = useQueryClient();

  const items = useMemo(() => {
    return [
      ...(self?.miroAccess
        ? [
            {
              title: 'Disconnect Miro',
              onClick: disconnectMiro,
            },
          ]
        : []),
      ...(self?.role === UserRole.ADMIN
        ? [
            {
              title: 'Invite new User',
              onClick: () => {
                setShowInviteModal(true);
              },
            },
          ]
        : []),
      {
        title: 'Sign out',
        onClick: async () => {
          try {
            await logout();
          } catch (e: any) {
            alert('Something went wrong, you have been logged out');
          } finally {
            localStorage.removeItem('userSession');
            qc.clear();
            await qc.invalidateQueries();
            navigate('/login');
          }
        },
      },
    ];
  }, [qc, logout, navigate, self]);
  return (
    <>
      <InviteModal
        isOpen={showInviteModal}
        onClose={() => {
          setShowInviteModal(false);
        }}
      />
      <Disclosure as="nav" className="bg-black font-['Inter']">
        {({open}) => {
          return (
            <>
              <div className="mx-auto px-2 header:px-6">
                <div className="relative flex h-16 items-center justify-between">
                  <div className="absolute inset-y-0 left-0 flex items-center header:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button
                      id="create-workshop-button-mobile"
                      className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    >
                      <span className="sr-only">Open main menu</span>
                      {open ? <XMarkIcon className="block h-6 w-6" aria-hidden="true" /> : <Bars3Icon className="block h-6 w-6" aria-hidden="true" />}
                    </Disclosure.Button>
                  </div>
                  <div className="flex flex-1 items-center justify-center header:items-stretch header:justify-start">
                    <div className="flex flex-shrink-0 items-center">
                      <NavLink to={RoutePath.root}>
                        {({isActive}) => {
                          return (
                            <div className="flex items-center block h-8 w-auto gap-2 cursor-pointer" aria-hidden>
                              <CompanyLogoLong className="h-[1.5em] header:hidden" iswhite={isActive ? 1 : 0} />
                              <CompanyLogoIcon className="h-[1.5em] hidden header:block" iswhite={isActive ? 1 : 0} />
                            </div>
                          );
                        }}
                      </NavLink>
                    </div>
                    <div className="hidden header:ml-6 header:block">
                      <div className="flex space-x-4">
                        {navigation.map((item) => {
                          return (
                            <NavLink
                              key={item.name}
                              className={({isActive}) =>
                                classNames(
                                  isActive ? 'bg-white' : 'text-white text-opacity-80 hover:bg-white hover:bg-opacity-40 hover:text-white cursor-pointer',
                                  'px-3 py-2 rounded-md text-sm font-medium'
                                )
                              }
                              aria-hidden
                              to={item.href}
                            >
                              {item.name}
                            </NavLink>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="absolute inset-y-0 right-0 flex items-center pr-2 header:static header:inset-auto header:ml-6 header:pr-0">
                    {/* <button
                    type="button"
                    className="rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  >
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button> */}

                    {/* Profile dropdown */}
                    {self && (
                      <NavLink to="/editor/new">
                        {({isActive}) =>
                          isActive ? null : (
                            <div
                              id="create-workshop-button"
                              className="hidden header:block min-w-[141px] hover:cursor-pointer ml-auto hidden text-helvetiablue border border-solid border-helvetiablue bg-black hover:bg-helvetiablue hover:bg-opacity-20 px-3 py-2 rounded-md text-sm font-medium"
                              aria-hidden
                            >
                              + New Workshop
                            </div>
                          )
                        }
                      </NavLink>
                    )}
                    <Menu
                      className="ml-3"
                      leftAligned={false}
                      triggerElement={
                        <div className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                          <span className="sr-only">Open user menu</span>
                          <div className="flex items-center justify-center h-8 w-8 rounded-full bg-white bg-opacity-40">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              style={{height: '32px', width: '32px'}}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                              />
                            </svg>
                          </div>
                        </div>
                      }
                      items={items}
                    />
                  </div>
                </div>
              </div>
              <Disclosure.Panel className="header:hidden">
                <div className="flex flex-col px-2 pt-2 gap-3 pb-3">
                  <div className="pt-2 gap-2">
                    {navigation.map((item) => {
                      return (
                        <NavLink
                          to={item.href}
                          key={item.name}
                          className={({isActive}) =>
                            classNames(
                              isActive ? 'bg-white' : 'text-white text-opacity-80 hover:bg-white hover:bg-opacity-40 hover:text-white',
                              'block px-3 py-2 rounded-md text-base font-medium'
                            )
                          }
                        >
                          {(isActive) => {
                            return <Disclosure.Button aria-current={isActive ? 'page' : undefined}>{item.name}</Disclosure.Button>;
                          }}
                        </NavLink>
                      );
                    })}
                  </div>
                  <NavLink to="/editor/new">
                    <Disclosure.Button
                      className="text-helvetiablue border border-solid hover:bg-helvetiablue hover:bg-opacity-40 block px-3 py-2 rounded-md text-base font-medium"
                      aria-current={undefined}
                    >
                      + New Workshop
                    </Disclosure.Button>
                  </NavLink>
                </div>
              </Disclosure.Panel>
            </>
          );
        }}
      </Disclosure>
    </>
  );
};

export const Header = React.memo(HeaderComponent);
