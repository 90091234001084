import React from 'react';
import {Button} from 'components';
import {MiroColor} from 'assets';

interface AddMiroButtonProps {
  onClick(): void;
}

export const AddMiroButton: React.FC<AddMiroButtonProps> = ({onClick}) => {
  return (
    <Button onClick={onClick} className="bg-white hover:bg-gray-50 !border-solid !border-[#CBD2D9] whitespace-nowrap">
      <MiroColor />
      <p className="text-black">Add Miro-Board</p>
    </Button>
  );
};
