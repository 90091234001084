import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Button, Col, Form, Input, InputNumber, Row, Select, Switch} from 'antd';
import {UseMutateAsyncFunction} from '@tanstack/react-query';
import {useCreateMethod, useDeleteMethod, useMethod, useMethodSubcategories, useUpdateMethod} from 'services/query';
import {Loader} from 'components';
import {upperFirst} from 'services/utils';
import {RoutePath} from 'Router';
import {toast} from 'react-toastify';
import {useNavigate, useParams} from 'react-router-dom';

import {MethodStatus} from 'types';
import {DynamicList, Uploader} from './components';
import {getFileObjectsToRemove, getFilesToUpload, setInitialFormValues} from './utils/helpers';

const {TextArea} = Input;

export const AdminMethodPage: React.FC = () => {
  const {id: methodId} = useParams();
  const [form] = Form.useForm<ToolboxObject.MethodDto>();
  const formRef = useRef(null);
  const navigate = useNavigate();
  const [isBreak, setIsBreak] = useState(false);

  const {mutateAsync: createMethod, isLoading: isCreateLoading} = useCreateMethod();
  const {mutateAsync: updateMethod, isLoading: isUpdateLoading} = useUpdateMethod(Number(methodId));
  const {mutateAsync: deleteMethod, isLoading: isDeleteLoading} = useDeleteMethod(Number(methodId));

  const isNew = methodId === 'new';

  const [filteredSubcategories, setFilteredSubcategories] = React.useState<ToolboxObject.Subcategory[]>([]);
  const [initialMethod, setInitialMethod] = React.useState<ToolboxObject.MethodDto>();

  const handleSubmit = (action: UseMutateAsyncFunction<any, unknown, FormData, unknown>, status: MethodStatus) => {
    const values = form.getFieldsValue();
    const filesToRemove = getFileObjectsToRemove(initialMethod!, values);
    const filesToUpload = getFilesToUpload(values);
    const formData = new FormData();
    filesToRemove.forEach((file) => formData.append('filesToRemove[]', file));
    filesToUpload.forEach((file) => formData.append('filesToUpload[]', file));
    for (const [key, value] of Object.entries(values)) {
      if (Array.isArray(value)) {
        value.forEach((val) => formData.append(`${key}[]`, val));
      } else {
        formData.set(key, value);
      }
    }
    formData.set('status', status);
    formData.delete('files');
    Object.entries(values.files).forEach(([key, fileValues]) => {
      fileValues.forEach((fileValue, index) => {
        formData.set(`files[${key}][${index}][id]`, fileValue.uid);
        formData.set(`files[${key}][${index}][name]`, fileValue.name);
        formData.set(`files[${key}][${index}][size]`, fileValue.size.toString());
      });
    });
    formData.set('is_break', isBreak.toString());
    action(formData)
      .then((method) => {
        let event = 'created';
        if (!isNew) {
          event = status === MethodStatus.PUBLISHED ? 'published' : 'updated';
        }
        toast.success(`Method ${event} successfully`, {
          position: 'top-center',
          autoClose: 1000,
          theme: 'dark',
        });
        if (isNew) {
          navigate(`${RoutePath.adminDashboard}/methods/${method.id}`);
        }
      })
      .catch(() => {
        toast.error('Something went wrong', {
          position: 'top-center',
          autoClose: 1000,
          theme: 'dark',
        });
      });
  };

  const handleCreate = () => {
    handleSubmit(createMethod, MethodStatus.DRAFT);
  };

  const handleUpdateDraft = () => {
    handleSubmit(updateMethod, MethodStatus.DRAFT);
  };

  const handlePublish = () => {
    handleSubmit(updateMethod, MethodStatus.PUBLISHED);
  };

  const handleDelete = () => {
    deleteMethod()
      .then(() => {
        toast.success(`Method deleted successfully`, {
          position: 'top-center',
          autoClose: 1000,
          theme: 'dark',
        });

        navigate(`${RoutePath.adminDashboard}/methods`);
      })
      .catch(() => {
        toast.error('Something went wrong', {
          position: 'top-center',
          autoClose: 1000,
          theme: 'dark',
        });
      });
  };

  const {data: method, isLoading} = useMethod(+methodId!, {
    enabled: !isNew && !!methodId,
  });
  const {data: allSubcategories, isLoading: isSubcategoriesLoading} = useMethodSubcategories();

  useEffect(() => {
    if (formRef.current && (method || isNew)) {
      setInitialFormValues(form, method);
      const formValues = form.getFieldsValue();
      setInitialMethod(formValues);
      setIsBreak(method?.is_break ?? false);
    }
  }, [method, formRef.current]);

  const filterSubcategories = (categoryId: number) => {
    if (allSubcategories) {
      const subcategories = allSubcategories.filter(({category}) => categoryId === category.id);
      setFilteredSubcategories(subcategories);
      const selectedSubcategory = form.getFieldValue('subcategory_id');
      if (!subcategories.map(({id: subcategoryId}) => subcategoryId).includes(selectedSubcategory)) {
        form.setFieldValue('subcategory_id', null);
      }
    }
  };

  useEffect(() => {
    if (method?.subcategory?.category.id) {
      filterSubcategories(method.subcategory.category.id);
    }
    if (isNew && allSubcategories) {
      setFilteredSubcategories(allSubcategories);
    }
  }, [method, allSubcategories]);
  const categories = useMemo(() => {
    const categoryIdMap: Record<number, boolean> = {};
    return allSubcategories
      ? allSubcategories.reduce((acc: ToolboxObject.Category[], {category}) => {
          if (categoryIdMap[category.id]) {
            return acc;
          }
          categoryIdMap[category.id] = true;
          return acc.concat(category);
        }, [])
      : [];
  }, [allSubcategories]);

  if ((!isNew && isLoading && !method) || isSubcategoriesLoading) {
    return (
      <div className="flex h-full">
        <Loader />
      </div>
    );
  }

  const isMutationLoading = isCreateLoading || isUpdateLoading || isDeleteLoading;

  return (
    <div className="flex flex-col px-2 lg:px-24 py-12 top-16 gap-4">
      <Form ref={formRef} form={form} wrapperCol={{span: 20}} layout="vertical" disabled={isMutationLoading}>
        <Row>
          <Col span={12}>
            <Form.Item label="Title" name="title" rules={[{required: true}]}>
              <Input placeholder="Title" />
            </Form.Item>
            <Form.Item label="Teaser" name="teaser" rules={[{required: true}]}>
              <TextArea rows={3} />
            </Form.Item>
            <Form.Item label="Duration" name="duration" initialValue={10} rules={[{required: true}]}>
              <InputNumber min={1} formatter={(val) => `${val} min`} style={{width: '100%'}} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Is 'Other Method'?">
              <Switch checked={isBreak} onChange={setIsBreak} />
            </Form.Item>
            <Uploader title="Method Icon" name={['files', 'icon_file']} accept="image/svg+xml" maxCount={1} />
          </Col>
        </Row>
        {!isBreak && (
          <div>
            <Row>
              <Col span={12}>
                <Form.Item label="Number of people" name="min_people" rules={[{required: true}]}>
                  <InputNumber min={1} style={{width: '100%'}} placeholder="Number of people" />
                </Form.Item>
                <Form.Item label="Difficulty" name="difficulty" rules={[{required: true}]}>
                  <Select placeholder="Difficulty">
                    <Select.Option value={1}>Easy</Select.Option>
                    <Select.Option value={2}>Medium</Select.Option>
                    <Select.Option value={3}>Hard</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item label="Description" name="description" rules={[{required: true}]}>
                  <TextArea rows={3} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Category" rules={[{required: true}]}>
                  <Select onChange={filterSubcategories} placeholder="Category" defaultValue={method?.subcategory?.category.id}>
                    {categories?.map((category) => (
                      <Select.Option value={category.id} key={category.id}>
                        {upperFirst(category.name)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item name="subcategory_id" label="Subcategory" rules={[{required: true}]}>
                  <Select placeholder="Subcategory">
                    {filteredSubcategories?.map((subcategory) => (
                      <Select.Option value={subcategory.id} key={subcategory.id}>
                        {upperFirst(subcategory.name)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Miro template board link" name="miro_href" rules={[{required: false}]}>
                  <Input placeholder="https://miro.com/app/board/BOARD_ID=/" allowClear />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Uploader title="Overview Images" name={['files', 'description']} accept="image/*" multiple />
              </Col>

              <Col span={6}>
                <Uploader title="Main Steps Image" name={['files', 'main_steps']} accept="image/*" maxCount={1} />
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <DynamicList name="requirements" title="Requirements" placeholder="Requirement" />
              </Col>
              <Col span={8}>
                <DynamicList name="results" title="Results" placeholder="Result" />
              </Col>
              <Col span={8}>
                <DynamicList name="benefits" title="Benefits" placeholder="Benefit" />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <DynamicList name="preparations" title="Preparation" placeholder="Step" />
              </Col>
              <Col span={12}>
                <DynamicList name="executions" title="Conducting the method" placeholder="Step" />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <DynamicList name="materials" title="Materials" placeholder="Material" />
              </Col>
              <Col span={12}>
                <DynamicList name="tips" title="Tips" placeholder="Tip" />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Uploader title="Attachments" name={['files', 'documents']} accept="application/pdf" multiple />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Uploader title="Attachment previews" name={['files', 'previews']} accept="image/*" multiple />
              </Col>
            </Row>
          </div>
        )}
        <div className="flex flex-col gap-8 items-center">
          <div className="flex justify-center gap-8">
            {isNew ? (
              <Button loading={isMutationLoading} onClick={handleCreate}>
                Create Draft
              </Button>
            ) : (
              <>
                <div className="flex gap-8">
                  <Button loading={isMutationLoading} onClick={handleUpdateDraft}>
                    {method?.status === MethodStatus.PUBLISHED ? 'Convert to Draft' : 'Update Draft'}
                  </Button>
                  <Button loading={isMutationLoading} onClick={handlePublish}>
                    {method?.status === MethodStatus.PUBLISHED ? 'Update' : 'Publish'}
                  </Button>
                </div>
                <Button danger loading={isMutationLoading} onClick={handleDelete}>
                  Delete
                </Button>
              </>
            )}
          </div>
          {!isNew && <p>Method Status: {method?.status}</p>}
        </div>
      </Form>
    </div>
  );
};
