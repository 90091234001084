import React, {useMemo} from 'react';
import Fuse from 'fuse.js';
import {MethodStatus} from 'types';
import {Collapsible, MethodLibraryTile} from '../../components';
import {LoadingComponent, useMethods} from '../../services/query';
import {groupMethodsByCategory, sortMethodCategories} from '../../services';

interface MethodLibraryProps {
  query?: string | undefined;
  handleMethodClick?(id: number): void;
  statuses?: MethodStatus[];
  showOthers?: boolean;
}

export const MethodLibrary: React.FC<MethodLibraryProps> = ({query = '', handleMethodClick = undefined, statuses, showOthers = false}) => {
  const methods = useMethods(statuses);
  const {data} = methods;

  const methodsByCategory = useMemo(() => {
    return data
      ? groupMethodsByCategory(
          query
            ? new Fuse(data, {keys: ['title', 'teaser'], includeScore: true, threshold: 0.5}).search(query).map((result) => {
                return result.item;
              })
            : data,
          showOthers
        )
      : [];
  }, [data, query]);

  return (
    <LoadingComponent query={methods}>
      {() => {
        return (
          <div className="flex flex-col gap-4">
            {Object.entries(methodsByCategory)
              .sort(sortMethodCategories)
              .map(([category, categoryMethods]) => {
                const key = query ? crypto.randomUUID() : category;
                return (
                  <Collapsible title={category} key={key}>
                    <div className="grid gap-6 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 mb-12 mt-6">
                      {categoryMethods.map((method: ToolboxObject.Method) => {
                        return <MethodLibraryTile method={method} key={method.id} handleClick={handleMethodClick} />;
                      })}
                    </div>
                  </Collapsible>
                );
              })}
          </div>
        );
      }}
    </LoadingComponent>
  );
};
