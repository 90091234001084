import React, {useEffect, useMemo, useRef, useState} from 'react';
import produce from 'immer';

interface EditableMethodTitleProps {
  method: ToolboxObject.EditableMethod;
  title: string;
  update: any;
}

export const EditableMethodTitle: React.FC<EditableMethodTitleProps> = ({method, title, update}) => {
  const [editingValue, setEditingValue] = useState(title);
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef(null);

  const showOriginalTitle = useMemo(() => {
    return method.original_method.title.toLowerCase().trim() !== editingValue.toLowerCase().trim();
  }, [method.original_method.title, editingValue]);

  function textAreaAdjust(event: any) {
    // eslint-disable-next-line no-param-reassign
    event.target.style.height = '1px';
    // eslint-disable-next-line no-param-reassign
    event.target.style.height = `${1 + event.target.scrollHeight}px`;
  }
  useEffect(() => {
    if (!isEditing) {
      return;
    }
    // @ts-ignore
    inputRef.current.style.height = '1px';
    // @ts-ignore
    inputRef.current.style.height = `${1 + inputRef.current.scrollHeight}px`;
  }, [isEditing, inputRef]);

  const onChange = (event: any) => {
    return setEditingValue(event.target.value);
  };
  const onKeyDown = (event: any) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.target.blur();
    } else if (event.key === 'Escape') {
      setEditingValue(title);
      event.target.blur();
    }
  };
  const moveCaretAtEnd = (e: {target: {value: string}}) => {
    const temp = e.target.value;
    e.target.value = '';
    e.target.value = temp;
  };
  const onBlur = (event: any) => {
    setIsEditing(false);
    if (event.target.value.trim() === '') {
      setEditingValue(title);
    } else {
      update(
        produce(method, (draft) => {
          // eslint-disable-next-line no-param-reassign
          draft.title = editingValue;
        })
      );
    }
    setIsEditing(false);
  };
  return (
    <div className="w-full h-full flex flex-wrap gap-0.5 mr-2 items-center">
      {isEditing ? (
        <textarea
          /* eslint-disable-next-line jsx-a11y/no-autofocus */
          autoFocus
          ref={inputRef}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          onFocus={moveCaretAtEnd}
          key={title}
          onKeyUp={textAreaAdjust}
          onClick={(e) => {
            return e.stopPropagation();
          }}
          className="px-1 ml-[-8px] resize-none mr-2 text-base leading-5 font-bold text-black break-words w-full rounded-md"
          value={editingValue}
        />
      ) : (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
        <p
          key={title}
          onClick={(event) => {
            event.stopPropagation();
            setIsEditing(true);
            if (inputRef.current) {
              // @ts-ignore
              inputRef.current.focus();
            }
          }}
          className="px-1 ml-[-8px] text-base leading-5 font-bold text-black break-words cursor-pointer w-fit rounded-md border hover:border-gray-100 border-white"
        >
          {editingValue}
        </p>
      )}
      {showOriginalTitle && !isEditing && (
        <p className="text-gray-500 text-[10px] sm:text-[12px] leading-3 font-medium text-gray-500 pt-0.5">{`– ${method.original_method.title}`}</p>
      )}
    </div>
  );
};
