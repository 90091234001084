import React from 'react';
import {useNavigate} from 'react-router-dom';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

interface NavTabsProps {
  tabs: string[];
  activeTab: string;
  setActiveTab: any;
  className?: string;
  disableNavigate?: boolean;
  titles?: (string | undefined)[];
}

export const NavTabs: React.FC<NavTabsProps> = ({tabs, activeTab, setActiveTab, disableNavigate = false, className = '', titles = undefined}) => {
  const navigate = useNavigate();
  return (
    <div className={classNames(className, 'flex gap-8')}>
      {tabs.map((tab, i) => {
        return (
          <button
            type="button"
            key={tab}
            className={classNames(
              'text-sm leading-5 font-medium pb-4 focus:outline-none capitalize',
              activeTab === tab ? 'border-black border-b-2 mb:[-4px]' : 'text-black/[0.6] hover:text-black'
            )}
            onClick={() => {
              if (!disableNavigate) {
                navigate(`../${tab}`, {replace: true});
              }
              setActiveTab(tab);
            }}
          >
            {titles && titles.length > i ? titles[i] ?? tab : tab}
          </button>
        );
      })}
    </div>
  );
};
