import React from 'react';

export const CompanyLogoIcon = ({...props}) => {
  return (
    <div className={props.className} style={{marginBottom: '12px'}}>
      <svg
        version="1.1"
        id="Ebene_1"
        xmlns="http://www.w3.org/2000/svg"
        // xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="169.939px"
        height="39.846px"
        viewBox="0 0 169.939 39.846"
        enableBackground="new 0 0 169.939 39.846"
        // xml:space="preserve"
      >
        <g>
          <path
            fill="white"
            d="M6.015,9.5v10.053h0.066c1.39-1.85,3.075-2.711,5.424-2.711c4.295,0,6.379,2.844,6.379,7.143v10.381h-6.016
		v-8.694c0-1.985-0.298-4.032-2.712-4.032c-2.379,0-3.141,2.047-3.141,4.032v8.694H0V9.5H6.015z"
          />
          <path
            fill="white"
            d="M38.282,26.896H25.62c0,2.444,1.291,3.768,3.77,3.768c1.29,0,2.214-0.431,2.876-1.553h5.785
		c-0.96,4-4.761,5.818-8.629,5.818c-5.62,0-9.819-3.173-9.819-9.025c0-5.655,3.867-9.061,9.389-9.061
		c5.885,0,9.289,3.638,9.289,9.424V26.896z M32.696,23.423c-0.299-1.621-1.786-2.68-3.406-2.68c-1.752,0-3.206,0.928-3.572,2.68
		H32.696z"
          />
          <path fill="white" d="M46.05,34.366h-6.015V9.5h6.015V34.366z" />
          <path fill="white" d="M57.465,26.103l4.034-8.698h6.745l-8.861,16.962h-3.835l-8.893-16.962h6.744L57.465,26.103z" />
          <path
            fill="white"
            d="M85.437,26.896H72.775c0,2.444,1.289,3.768,3.77,3.768c1.289,0,2.215-0.431,2.875-1.553h5.785
		c-0.958,4-4.761,5.818-8.626,5.818c-5.62,0-9.819-3.173-9.819-9.025c0-5.655,3.869-9.061,9.388-9.061
		c5.884,0,9.29,3.638,9.29,9.424V26.896z M79.849,23.423c-0.296-1.621-1.784-2.68-3.403-2.68c-1.753,0-3.208,0.928-3.571,2.68
		H79.849z"
          />
          <path fill="white" d="M94.31,34.366h-6.016v-12h-1.95v-4.962h1.95v-5.09h6.016v5.09h3.405v4.962H94.31V34.366z" />
          <path
            fill="white"
            d="M105.237,12.05c0,1.851-1.518,3.371-3.371,3.371c-1.85,0-3.372-1.52-3.372-3.371
		c0-1.852,1.522-3.372,3.372-3.372C103.72,8.678,105.237,10.198,105.237,12.05z M104.875,34.366h-6.019V17.404h6.019V34.366z"
          />
          <path
            fill="white"
            d="M126.397,34.366h-6.02V32.48h-0.064c-1.06,1.688-3.141,2.448-5.124,2.448c-5.026,0-8.563-4.199-8.563-9.06
		c0-4.862,3.472-9.027,8.498-9.027c1.948,0,3.997,0.728,5.254,2.216v-1.654h6.02V34.366z M112.843,25.903
		c0,2.148,1.42,3.8,3.866,3.8s3.868-1.651,3.868-3.8c0-2.083-1.422-3.836-3.868-3.836S112.843,23.82,112.843,25.903z"
          />
          <g>
            <g>
              <g>
                <polygon fill="#563B6C" points="147.832,27.238 140.111,29.221 146.111,15.355 153.86,13.507 				" />
              </g>
            </g>
            <g>
              <polygon fill="#71518C" points="145.726,1.699 153.506,0 156.638,7.181 148.879,8.967 			" />
            </g>
            <g>
              <polygon fill="#8761A8" points="134.379,28.239 145.726,1.699 148.879,8.967 140.111,29.221 			" />
            </g>
          </g>
          <g>
            <polygon fill="#118289" points="140.111,29.221 147.832,27.238 160.77,29.398 153.069,31.438 		" />
            <g>
              <polygon fill="#46A8B3" points="156.236,38.762 131.764,34.366 134.379,28.239 153.069,31.438 			" />
            </g>
          </g>
          <g>
            <g>
              <polygon fill="#9F1717" points="148.879,8.967 156.638,7.181 169.939,37.689 162.277,39.846 			" />
            </g>
            <g>
              <polygon fill="#C21924" points="146.111,15.355 148.879,8.967 162.277,39.846 156.236,38.762 			" />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};
