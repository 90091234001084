import React from 'react';
import {useDocumentTitle} from 'hooks';
import {HowToDocument} from './HowToDocument';
import {bannerHowTo} from '../../assets';

export const HowToPage: React.FC = () => {
  useDocumentTitle('howto');

  return (
    <div>
      <div className="flex flex-col px-4 sm:px-6 lg:px-24 py-12 top-16 gap-8 max-w-[1600px] m-auto">
        <p className="text-2xl font-extrabold text-gray-900">How-To</p>
        <img src={bannerHowTo} alt="banner how to" className="h-[240px] w-full object-cover" />
        <div className="flex flex-wrap gap-4 gap-y-8 justify-between">
          <HowToDocument title="Workshop Leitfaden" description="Erklärungen zum Aufbau eines erfolgreichen Workshops" />
          <HowToDocument title="Tipps und Inputs" description="Tipps und Inputs im Umgang mit kontinuierlicher Veränderung" />
          <HowToDocument title="Umgang mit Ängsten" description="Umgang mit Ängsten und Fragen im Team entlang der Change Kurve" />
          <HowToDocument title="Weiteres..." description="Weitere Dokumente für die Durchführung von Workshops" />
        </div>
      </div>
    </div>
  );
};
