import React, {useEffect, useRef, useState} from 'react';
import produce from 'immer';
import classNames from 'classnames';

interface EditableMethodDescriptionProps {
  method: ToolboxObject.EditableMethod;
  description: string | null;
  update: any;
}

export const EditableMethodDescription: React.FC<EditableMethodDescriptionProps> = ({method, description, update}) => {
  const [editingValue, setEditingValue] = useState(description);
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef(null);

  function textAreaAdjust(event: any) {
    // eslint-disable-next-line no-param-reassign
    event.target.style.height = '1px';
    // eslint-disable-next-line no-param-reassign
    event.target.style.height = `${1 + event.target.scrollHeight}px`;
  }

  useEffect(() => {
    if (!isEditing) {
      return;
    }
    // @ts-ignore
    inputRef.current.style.height = '1px';
    // @ts-ignore
    inputRef.current.style.height = `${1 + inputRef.current.scrollHeight}px`;
  }, [isEditing, inputRef]);

  const onChange = (event: any) => {
    return setEditingValue(event.target.value);
  };
  const onKeyDown = (event: any) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.target.blur();
    } else if (event.key === 'Escape') {
      setEditingValue(description);
      event.target.blur();
    }
  };
  const moveCaretAtEnd = (e: {target: {value: string}}) => {
    const temp = e.target.value;
    e.target.value = '';
    e.target.value = temp;
  };
  const onBlur = () => {
    setIsEditing(false);

    update(
      produce(method, (draft) => {
        // eslint-disable-next-line no-param-reassign
        draft.teaser = editingValue;
      })
    );
    setIsEditing(false);
  };
  const showDescription = editingValue && editingValue.trim() !== '';
  return (
    <div className="w-full h-full group-hover/step:bg-white rounded-md border group-hover/step:border-gray-100 border-white">
      {isEditing ? (
        <textarea
          /* eslint-disable-next-line jsx-a11y/no-autofocus */
          autoFocus
          ref={inputRef}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          onFocus={moveCaretAtEnd}
          key={description}
          onKeyUp={textAreaAdjust}
          onClick={(e) => {
            return e.stopPropagation();
          }}
          className="px-1 ml-[-8px] resize-none w-full text-xs sm:text-sm leading-5 font-medium text-black rounded-md border hover:border-gray-100 border-white overflow-hidden -mb-1"
          value={editingValue ?? ''}
        />
      ) : (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
        <p
          key={description}
          onClick={(event) => {
            event.stopPropagation();
            setIsEditing(true);
            if (inputRef.current) {
              // @ts-ignore
              inputRef.current.focus();
            }
          }}
          className={classNames(
            'px-1 ml-[-8px] text-xs sm:text-sm leading-5 font-medium text-gray-500 cursor-pointer rounded-md border hover:border-gray-100 border-white hover:text-black',
            showDescription ? '' : 'italic'
          )}
        >
          {showDescription ? editingValue : '+ Add Description'}
        </p>
      )}
    </div>
  );
};
