import React from 'react';
import {Button} from 'components';
import {ThreeDots} from 'assets';
import {Popover, Transition} from '@headlessui/react';
import {flip, offset, shift, useFloating} from '@floating-ui/react-dom';
import {DeleteButton} from './DeleteButton';

interface EditorMenuButtonProps {
  handleDelete: () => void;
  showExportRecipe: boolean;
  handleExportRecipe: () => void;
}

export const EditorMenuButton: React.FC<EditorMenuButtonProps> = ({handleDelete, showExportRecipe, handleExportRecipe}) => {
  const {refs, floatingStyles} = useFloating({placement: 'bottom-end', middleware: [offset(10), flip(), shift()]});
  return (
    <Popover as="div">
      {({open, close}) => (
        <>
          <Popover.Button
            ref={refs.setReference}
            className="rounded-md shadow-sm focus:outline-none focus:ring-0 bg-white hover:bg-gray-50 border border-[#CBD2D9] whitespace-nowrap my-auto w-[44px] h-[44px] flex items-center justify-center focus:outline-none"
            as="button"
          >
            <ThreeDots />
          </Popover.Button>
          <Popover.Panel>
            {open && (
              <Transition
                as={React.Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <div
                  className="absolute flex flex-col gap-y-[1px] z-10 shadow-md p-0 w-[224px] bg-gray-50 [&>*:last-child]:rounded-b-md [&>*:first-child]:rounded-t-md rounded-md"
                  ref={refs.setFloating}
                  style={floatingStyles}
                >
                  {showExportRecipe && (
                    <Button onClick={handleExportRecipe} className="!text-black bg-white rounded-none hover:bg-gray-50 focus:ring-0 focus:outline-none">
                      <p>Save as Recipe</p>
                    </Button>
                  )}
                  <DeleteButton
                    handleDelete={() => {
                      handleDelete();
                      close();
                    }}
                    className="!text-black bg-white rounded-none hover:bg-gray-50 focus:ring-0 focus:outline-none"
                  />
                </div>
              </Transition>
            )}
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
};
