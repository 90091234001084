import React from 'react';

import {ClipBoardCheck, Download, ShoppingBag} from 'assets';
import {Card} from 'components';

import styles from './Resource.module.scss';
import {upperFirst} from '../../../../services';

interface ResourcesProps {
  method: ToolboxObject.MethodDetails;
}

export const Resources: React.FC<ResourcesProps> = ({method}) => {
  return method?.materials?.length ? (
    <div className={styles.resources}>
      <Card headerIcon={<ClipBoardCheck />} headerTitle="Material" color={method.subcategory?.category.color}>
        <ul>
          {method.materials.map((material) => {
            return <li key={material.id}>{upperFirst(material.name)}</li>;
          })}
        </ul>
      </Card>
      <div className={styles.wrapper}>
        {method.files?.documents?.map((doc) => {
          const downloadLink = `${process.env.REACT_APP_TOOLBOX_HOST}/files/${doc.id}/download`;
          return (
            <div className={styles.card} key={doc.id}>
              <div className={styles.imageWrapper}>
                <img key={doc.id} src={doc.previewLink} className={styles.image} alt={doc.display_name} />
              </div>
              <div className={styles.cardInfo}>
                <p className={styles.text}>{doc.original_name}</p>
                <div className={styles.cardActions}>
                  <a href={downloadLink} target="_blank" rel="noreferrer" className={styles.link}>
                    <Download /> Download
                  </a>
                  <button type="button" className={styles.link}>
                    {' '}
                    <a
                      href="https://mokojam.com/collections/printing"
                      className="inline-flex gap-1.5 items-center justify-center"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <ShoppingBag />
                      Order Poster
                    </a>
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  ) : null;
};
