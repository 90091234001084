import React from 'react';
import {useCreateWorkshop} from 'services/query';
import {toast} from 'react-toastify';
import {useLocation, useNavigate} from 'react-router-dom';
import {WorkshopTemplate} from './WorkshopTemplate';

export const NewWorkshopPage: React.FC = () => {
  const navigate = useNavigate();
  const {state} = useLocation();

  const {mutate: create} = useCreateWorkshop();
  const handleSave = React.useCallback(
    (
      title: string,
      sessions: ToolboxObject.WorkshopSession[],
      description: string,
      requirements: string[],
      benefits: string[],
      category: ToolboxObject.WorkshopCategory | null,
      teaser: string | null
    ) => {
      const data = {
        title,
        sessions,
        description,
        requirements,
        benefits,
        category,
        teaser,
      };
      create(data, {
        onSuccess: (savedWorkshop) => {
          toast.success('Workshop created successfully', {
            position: 'top-center',
            autoClose: 1000,
            theme: 'dark',
          });
          return navigate(`/editor/${savedWorkshop.id}`);
        },
        onError: () => {
          return toast.error('Something went wrong, workshop could not be saved', {
            position: 'top-center',
            autoClose: 1000,
            theme: 'dark',
          });
        },
      });
    },
    [create, navigate]
  );
  return (
    <WorkshopTemplate
      handleSave={handleSave}
      workshop={{
        sessions: state?.recipe_sessions ?? undefined,
        description: state?.recipe_description,
        benefits: state?.recipe_benefits,
        requirements: state?.recipe_requirements,
      }}
    />
  );
};
