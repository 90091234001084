import React from 'react';
import {useCreateRecipe, useDeleteWorkshop, useSaveWorkshop, useWorkshop} from 'services/query';
import {toast} from 'react-toastify';
import {useNavigate, useParams} from 'react-router-dom';
import {Loader} from 'components';
import {RoutePath} from 'Router';
import axios, {AxiosError} from 'axios';
import {WorkshopTemplate} from './WorkshopTemplate';

interface WorkshopPageProps {
  recipe?: boolean;
}
export const WorkshopPage: React.FC<WorkshopPageProps> = ({recipe = false}) => {
  const navigate = useNavigate();
  const params = useParams();
  const workshopId = Number(params.workshopId);
  const {data: workshop, isLoading, error} = useWorkshop(workshopId, recipe);

  const {mutate: save, isLoading: isSaveLoading} = useSaveWorkshop(recipe);
  const {mutate: createRecipe, isLoading: isCreateRecipeLoading} = useCreateRecipe();
  const handleSave = React.useCallback(
    (
      title: string,
      sessions: ToolboxObject.WorkshopSession[],
      description: string,
      requirements: string[],
      benefits: string[],
      category: ToolboxObject.WorkshopCategory | null,
      teaser: string | null
    ) => {
      const data = {
        id: workshopId,
        title,
        sessions,
        description,
        requirements,
        benefits,
        category: recipe ? category : null,
        teaser: recipe ? teaser : null,
      };
      save(data, {
        onSuccess: () => {
          return toast.success(`${recipe ? 'Recipe' : 'Workshop'} updated successfully`, {
            position: 'top-center',
            autoClose: 1000,
            theme: 'dark',
          });
        },
        onError: () => {
          return toast.error(`Something went wrong, ${recipe ? 'Recipe' : 'Workshop'} could not be saved`, {
            position: 'top-center',
            autoClose: 1000,
            theme: 'dark',
          });
        },
      });
    },
    [workshopId, save]
  );
  const handleExportAsRecipe = React.useCallback(
    (
      title: string,
      sessions: ToolboxObject.WorkshopSession[],
      description: string,
      requirements: string[],
      benefits: string[],
      category: ToolboxObject.WorkshopCategory | null,
      teaser: string
    ) => {
      const data = {
        id: workshopId,
        title,
        sessions,
        description,
        requirements,
        benefits,
        category,
        teaser,
      };
      createRecipe(data, {
        onSuccess: () => {
          navigate('/library');
          return toast.success('Exported workshop as recipe successfully', {
            position: 'top-center',
            autoClose: 1000,
            theme: 'dark',
          });
        },
        onError: () => {
          return toast.error(`Something went wrong, recipe could not be saved`, {
            position: 'top-center',
            autoClose: 1000,
            theme: 'dark',
          });
        },
      });
    },
    [workshopId, save]
  );

  const {mutate: deleteWorkshop, isLoading: isDeleteLoading} = useDeleteWorkshop(recipe);
  const handleDelete = React.useCallback(
    (title: string) => {
      deleteWorkshop(
        {id: workshopId},
        {
          onSuccess: () => {
            toast.success(`${recipe ? 'Recipe' : 'Workshop'} "${title}" deleted successfully`, {
              position: 'top-center',
              autoClose: 2000,
              theme: 'dark',
            });
            return navigate(recipe ? RoutePath.library : RoutePath.workshopLibrary);
          },
          onError: () => {
            return toast.error(`Something went wrong, ${recipe ? 'Recipe' : 'Workshop'} could not be deleted`, {
              position: 'top-center',
              autoClose: 1000,
              theme: 'dark',
            });
          },
        }
      );
    },
    [workshopId, deleteWorkshop, navigate]
  );

  if (isLoading) {
    return <Loader />;
  }
  if (!workshop && error) {
    if ((error as AxiosError).status === axios.HttpStatusCode.NotFound) {
      toast.error("Workshop doesn't exist", {
        position: 'top-center',
        autoClose: 1000,
        theme: 'dark',
      });
    }
    navigate(recipe ? RoutePath.library : RoutePath.workshopLibrary);
    return null;
  }
  return (
    <WorkshopTemplate
      handleSave={handleSave}
      handleDelete={handleDelete}
      handleExportAsRecipe={handleExportAsRecipe}
      workshop={workshop}
      isMutationLoading={isSaveLoading || isDeleteLoading || isCreateRecipeLoading}
    />
  );
};
