import React, {useState} from 'react';
import {Button} from 'components';
import {ContentSave, LinkIcon, MiroColor, UnlinkIcon} from 'assets';
import {ChevronDownIcon} from '@heroicons/react/20/solid';
import {Popover, Transition} from '@headlessui/react';
import {useFloating, offset, flip, shift} from '@floating-ui/react-dom';

interface OpenMiroButtonProps {
  href: string;
  linkMiroBoard(href: string | null): void;
}

export const OpenMiroButton: React.FC<OpenMiroButtonProps> = ({href, linkMiroBoard}) => {
  const [hrefState, setHrefState] = useState(href);
  const {refs, floatingStyles} = useFloating({placement: 'bottom-end', middleware: [offset(10), flip(), shift()]});
  const onChangeHref = (event: any) => {
    return setHrefState(event.target.value);
  };
  return (
    <div className="flex flex-wrap-none">
      <Button
        onClick={() => {
          window.open(href, '_blank', 'noreferrer');
        }}
        className="bg-white hover:bg-gray-50 !border-solid !border-[#CBD2D9] whitespace-nowrap rounded-r-none focus:rounded-md focus:z-10"
      >
        <MiroColor />
        <p className="text-black">Open Miro-Board</p>
      </Button>
      <Popover as="div">
        {({open, close}) => (
          <>
            <Popover.Button
              ref={refs.setReference}
              className="flex justify-center h-full bg-white hover:bg-gray-50 border-solid border-[#CBD2D9] rounded-md rounded-l-none border-l-0 items-center border focus:outline-none w-[44px] h-[44px]"
              as="button"
            >
              <ChevronDownIcon width="20px" height="20px" color="black" className="ui-open:rotate-180 ui-open:transform" />
            </Popover.Button>
            <Popover.Panel>
              {open && (
                <Transition
                  as={React.Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <div className="absolute flex flex-col gap-2 bg-white z-10 shadow-md px-2 py-3 w-[320px]" ref={refs.setFloating} style={floatingStyles}>
                    <div className="relative">
                      <input
                        type="text"
                        aria-label="Field name"
                        value={hrefState}
                        onChange={onChangeHref}
                        className="w-full border border-gray-200 transition-all rounded-md pl-1 focus:bg-transparent hover:bg-gray-50 placeholder:italic placeholder:text-gray-200 text-base mr-2"
                        placeholder="Paste Url"
                      />
                      {!hrefState && (
                        <div className="absolute inset-y-0 right-0 flex items-center pr-2">
                          <LinkIcon />
                        </div>
                      )}
                    </div>
                    <div className="flex gap-2 justify-between">
                      <Button
                        onClick={() => {
                          if (hrefState) {
                            linkMiroBoard(hrefState);
                            close();
                          }
                        }}
                        className="w-full !gap-2"
                      >
                        <div className="w-[20px] h-[20px]">
                          <ContentSave fill="white" />
                        </div>
                        <p>Save</p>
                      </Button>
                      <Button
                        onClick={() => {
                          linkMiroBoard(null);
                          close();
                        }}
                        className="w-full bg-deletered !gap-2"
                      >
                        <div className="w-[20px] h-[20px]">
                          <UnlinkIcon fill="white" />
                        </div>
                        <p>Unlink</p>
                      </Button>
                    </div>
                  </div>
                </Transition>
              )}
            </Popover.Panel>
          </>
        )}
      </Popover>
    </div>
  );
};
