import React from 'react';
import {Card} from 'components';
import {Flag, Puzzle, Fire} from 'assets';

import styles from './DescriptionCards.module.scss';
import {upperFirst} from '../../../../services';

interface DescriptionCardsProps {
  method: ToolboxObject.MethodDetails;
}

export const DescriptionCards: React.FC<DescriptionCardsProps> = ({method}) => {
  const color = method.subcategory?.category.color;
  return (
    <div className={styles.descriptionCards}>
      <div className={styles.wrapper}>
        <Card headerIcon={<Puzzle />} headerTitle="Requirements" color={color}>
          <ul>
            {method?.infos?.requirement?.length ? (
              method.infos.requirement.map((info) => {
                return <li key={info.id}>{upperFirst(info.description)}</li>;
              })
            ) : (
              <li>coming soon</li>
            )}
          </ul>
        </Card>

        <Card headerIcon={<Flag />} headerTitle="Results" color={color}>
          <ul>
            {method?.infos?.result?.length ? (
              method.infos.result.map((info) => {
                return <li key={info.id}>{upperFirst(info.description)}</li>;
              })
            ) : (
              <li>coming soon</li>
            )}
          </ul>
        </Card>
      </div>
      <Card headerIcon={<Fire />} headerTitle="Benefits" color={color}>
        <ul>
          {method?.infos?.benefit?.length ? (
            method.infos.benefit.map((info) => {
              return <li key={info.id}>{upperFirst(info.description)}</li>;
            })
          ) : (
            <li>coming soon</li>
          )}
        </ul>
      </Card>
    </div>
  );
};
