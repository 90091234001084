import React from 'react';
import classnames from 'classnames';

import styles from './Steps.module.scss';
import {upperFirst} from '../../../../services';

interface StepsProps {
  method: ToolboxObject.MethodDetails;
}

const orderSteps = (stepsList: ToolboxObject.Step[]) => {
  return [...stepsList].sort((a, b) => {
    return a.order - b.order;
  });
};

export const Steps: React.FC<StepsProps> = ({method}) => {
  return (
    <div className={styles.steps}>
      {method.files?.main_steps && (
        <div>
          <h2 className={classnames(styles.heading, 'mb-8')}>Main Steps</h2>
          {method.files.main_steps.map((file) => (
            <img src={file.link} key={file.id} alt={file.display_name} />
          ))}
        </div>
      )}
      <div className={styles.wrapper}>
        {method.steps.preparation && (
          <div className={styles.card}>
            <h3 className={styles.heading}>Preparation</h3>
            <ul>
              {orderSteps(method.steps.preparation).map((step) => {
                return (
                  <li key={step.id}>
                    <span className={styles.number}>{step.order}</span>
                    <span className={styles.text}>{upperFirst(step.description)}</span>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        {method.steps.execution && (
          <div className={styles.card}>
            <h3 className={styles.heading}>Conducting the method </h3>
            <ul>
              {orderSteps(method.steps.execution).map((step) => {
                return (
                  <li key={step.id}>
                    <span className={styles.number}>{step.order}</span>
                    <span className={styles.text}>{upperFirst(step.description)}</span>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};
