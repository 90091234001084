import {RoutePath} from 'Router';
import {AxiosError, HttpStatusCode} from 'axios';
import {Loader} from 'components/Loader';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {useMethod} from 'services/query';
import {MethodDetail} from './MethodDetail';

interface MethodDetailContainerProps {
  id: number;
}

export const MethodDetailContainer: React.FC<MethodDetailContainerProps> = ({id}) => {
  const navigate = useNavigate();
  const {data: method, isLoading, error} = useMethod(id);

  if (!method && error) {
    if ((error as AxiosError).status === HttpStatusCode.NotFound) {
      toast.error("Method does't exist", {
        position: 'top-center',
        autoClose: 1000,
        theme: 'dark',
      });
    }
    navigate(RoutePath.workshopLibrary);
    return null;
  }

  if (isLoading) {
    return (
      <div className="flex w-full h-full">
        <Loader />
      </div>
    );
  }
  return method ? <MethodDetail method={method} /> : null;
};
