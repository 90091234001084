import React, {useMemo} from 'react';

import {useDocumentTitle} from 'hooks';
import {HomeTile} from '../../components/HomeTile';
import {ArrowRight, BuildYourOwnWorkshop, HowTo, kiefer, ReadyToUseFormate, reolon, vuono} from '../../assets';
import {MethodLibraryTile, RecipeLibraryTile} from '../../components';
import {LoadingComponent, useMethods, useRecipes, useSelf, useWorkshops} from '../../services/query';
import {WorkshopLibraryTile} from '../../components/LibraryTile';

interface RandomRecipeProps {
  recipes: ToolboxObject.Workshop[];
}

export const RandomRecipe: React.FC<RandomRecipeProps> = ({recipes}) => {
  if (recipes?.length) {
    return <RecipeLibraryTile recipe={recipes[Math.floor(Math.random() * recipes.length)]} />;
  }
  return null;
};

interface RandomMethodProps {
  methods: ToolboxObject.Method[];
}

const RandomMethod: React.FC<RandomMethodProps> = ({methods}) => {
  if (methods?.length) {
    return <MethodLibraryTile method={methods[Math.floor(Math.random() * methods.length)]} />;
  }
  return null;
};

const sortRecent = (a: ToolboxObject.Method | ToolboxObject.Workshop, b: ToolboxObject.Method | ToolboxObject.Workshop) => {
  if (a.user_data?.last_viewed === b.user_data?.last_viewed) {
    return 0;
  }
  if (!a.user_data || !a.user_data.last_viewed) {
    return 1;
  }
  if (!b.user_data || !b.user_data.last_viewed) {
    return -1;
  }
  if (a.user_data.last_viewed < b.user_data.last_viewed) {
    return 1;
  }
  return -1;
};

export const RootPage: React.FC = () => {
  useDocumentTitle('root');

  const self = useSelf();
  const methods = useMethods();
  const recipes = useRecipes();
  const workshops = useWorkshops();
  const recentMethods = useMemo(() => {
    if (!methods.data) {
      return [];
    }
    return [...methods.data].sort(sortRecent).slice(0, 3);
  }, [methods.data]);

  const recentRecipes = useMemo(() => {
    if (!recipes.data) {
      return [];
    }
    return [...recipes.data].sort(sortRecent).slice(0, 3);
  }, [recipes.data]);

  const recentWorkshops = useMemo(() => {
    if (!workshops.data) {
      return [];
    }
    return [...workshops.data].sort(sortRecent).slice(0, 3);
  }, [workshops.data]);

  const welcomeMessage = 'How would you like to start today?';
  return (
    <div>
      <div className="m-auto flex flex-col items-center">
        <div className="w-full bg-black pt-8">
          <div className="w-full m-auto pb-14 px-4 sm:px-24 max-w-[1600px]">
            <div className="flex flex-col gap-8 items-start">
              <div className="flex flex-col gap-4">
                <div className="flex items-center whitespace-pre text-white text-xl leading-8 font-medium">
                  <p>{`Welcome back, `}</p>
                  <LoadingComponent query={self} pulse>
                    {() => {
                      return <p>{self.data?.first_name || self.data?.email}</p>;
                    }}
                  </LoadingComponent>
                </div>
                <p className="text-white text-2xl font-extrabold ">{welcomeMessage}</p>
              </div>
              <div className="flex flex-row w-full justify-center flex-wrap gap-4">
                <div className="flex flex-1 w-full flex-wrap justify-center gap-6">
                  <HomeTile text="Build your own Workshop" nav="/editor/new" className="hover:border-helvetiapurple hover:shadow-poppurple">
                    <BuildYourOwnWorkshop />
                  </HomeTile>
                  <HomeTile text="Ready to use Formate" nav="/library/recipes" className="hover:border-helvetiared hover:shadow-popred">
                    <ReadyToUseFormate />
                  </HomeTile>
                  <HomeTile text="How-To" nav="/howto" className="hover:border-helvetiablue hover:shadow-popblue">
                    <HowTo />
                  </HomeTile>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-12 w-full px-4 pt-14 pb-32 sm:px-24 max-w-[1600px]">
          <LoadingComponent query={methods}>
            {() => {
              return (
                <>
                  {!!workshops.data?.length && (
                    <div className="flex flex-col gap-6">
                      <div className="flex justify-between px-2 py-1 w-full bg-gray-100  rounded-md">
                        <p className="text-base font-bold">Recent Works</p>
                        <a href="/workshops">
                          <div className="flex items-center justify-center gap-3 cursor-pointer">
                            <p className="text-base font-bold">Show all</p>
                            <ArrowRight />
                          </div>
                        </a>
                      </div>
                      <div className="grid gap-5 grid-cols-1 xl:grid-cols-3">
                        {!!recentWorkshops.length &&
                          recentWorkshops.map((recentWorkshop) => {
                            return <WorkshopLibraryTile workshop={recentWorkshop} key={recentWorkshop.id} />;
                          })}
                      </div>
                    </div>
                  )}
                  <div className="flex flex-col gap-6">
                    <div className="flex justify-between px-2 py-1 w-full bg-gray-100  rounded-md">
                      <p className="text-base font-bold">Recent Recipes</p>
                      <a href="/library/recipes">
                        <div className="flex items-center justify-center gap-3 cursor-pointer">
                          <p className="text-base font-bold">Show all</p>
                          <ArrowRight />
                        </div>
                      </a>
                    </div>
                    <div className="grid sm:auto-rows-[minmax(0,200px)] md:auto-rows-[minmax(0,156px)] lg:auto-rows-[minmax(0,300px)] xl:auto-rows-[minmax(0,226px)] 2xl:auto-rows-[minmax(0,180px)] gap-6 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3">
                      {recentRecipes.length ? (
                        recentRecipes.map((recentRecipe) => {
                          return <RecipeLibraryTile key={recentRecipe.id} recipe={recentRecipe} />;
                        })
                      ) : recipes.data?.length ? (
                        <>
                          <RandomRecipe recipes={recipes.data} />
                          <RandomRecipe recipes={recipes.data} />
                          <RandomRecipe recipes={recipes.data} />
                        </>
                      ) : null}
                    </div>
                  </div>
                  <div className="flex flex-col gap-6">
                    <div className="flex justify-between px-2 py-1 w-full bg-gray-100  rounded-md">
                      <p className="text-base font-bold">Recent Methods</p>
                      <a href="/library/methods">
                        <div className="flex items-center justify-center gap-3 cursor-pointer">
                          <p className="text-base font-bold">Show all</p>
                          <ArrowRight />
                        </div>
                      </a>
                    </div>
                    <div className="grid gap-5 grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3">
                      {recentMethods.length ? (
                        recentMethods.map((recentMethod) => {
                          return <MethodLibraryTile key={recentMethod.id} method={recentMethod} />;
                        })
                      ) : methods.data?.length ? (
                        <>
                          <RandomMethod methods={methods.data || []} />
                          <RandomMethod methods={methods.data || []} />
                          <RandomMethod methods={methods.data || []} />
                        </>
                      ) : null}
                    </div>
                  </div>
                </>
              );
            }}
          </LoadingComponent>
        </div>
        <div className="w-full bg-black py-16 px-4 sm:px-24">
          <div className="overflow-hidden bg-white rounded-xl flex w-full m-auto pt-8 pb-8 px-8 lg:p-8 max-w-[1408px] flex-col gap-8">
            <div className="text-left">
              <h2 className="text-2xl font-['Inter'] font-bold tracking-tight text-black">Change & Development</h2>
              <p className="mt-6 text-md font-['Inter'] text-gray-400">
                Wir sind für dich da: Wenn du Fragen oder Anregungen hast, die helfen, die Methoden weiter zu entwickeln und damit praxistauglich zu halten oder
                wenn du Beratung bzw. weitere Unterstützung bei der Begleitung oder Durchführung einer Massnahme wünschst, melde dich bei uns.
              </p>
            </div>
            <div className="flex w-full max-w-[1040px] gap-4 my-auto flex-wrap justify-center lg:justify-around mx-auto">
              <div className="flex flex-col gap-2 relative max-w-[250px]">
                <img className="m-0 rounded-md" src={reolon} alt="Daniela Reolon" />
                <p className="text-md font-['Inter'] text-gray-400">Daniela Reolon</p>
              </div>
              <div className="flex flex-col gap-2 relative max-w-[250px]">
                <img className="m-0 rounded-md" src={kiefer} alt="Regina Kiefer" />
                <p className="text-md font-['Inter'] text-gray-400">Regina Kiefer</p>
              </div>
              <div className="flex flex-col gap-2 relative max-w-[250px]">
                <img className="m-0 rounded-md" src={vuono} alt="Claudio Vuono" />
                <p className="text-md font-['Inter'] text-gray-400">Claudio Vuono</p>
              </div>
            </div>
            <div className="justify-center gap-x-6 lg:justify-start inline-flex items-center rounded-md border border-transparent bg-black px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-opacity-70 focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2 gap-3 h-fit w-fit">
              <a href="mailto:change.development@helvetia.ch" target="_blank" rel="noreferrer" className="inline-flex gap-1.5 items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                  />
                </svg>
                Kontaktiere uns
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
