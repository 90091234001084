import React, {useEffect, useRef, useState} from 'react';
import produce from 'immer';
import {DeleteStep, DragStep} from '../../../assets';

interface EditableMethodStepProps {
  method: ToolboxObject.EditableMethod;
  step: string;
  index: number;
  update: any;
}

export const EditableMethodStep: React.FC<EditableMethodStepProps> = ({method, step, index, update}) => {
  const [editingValue, setEditingValue] = useState(step);
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef(null);

  function textAreaAdjust(event: any) {
    // eslint-disable-next-line no-param-reassign
    event.target.style.height = '1px';
    // eslint-disable-next-line no-param-reassign
    event.target.style.height = `${1 + event.target.scrollHeight}px`;
  }
  useEffect(() => {
    if (!isEditing) {
      return;
    }
    // @ts-ignore
    inputRef.current.style.height = '1px';
    // @ts-ignore
    inputRef.current.style.height = `${1 + inputRef.current.scrollHeight}px`;
  }, [isEditing, inputRef]);

  const onChange = (event: any) => {
    return setEditingValue(event.target.value);
  };
  const onKeyDown = (event: any) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.target.blur();
    } else if (event.key === 'Escape') {
      setEditingValue(step);
      event.target.blur();
    }
  };
  const moveCaretAtEnd = (e: {target: {value: string}}) => {
    const temp = e.target.value;
    e.target.value = '';
    e.target.value = temp;
  };
  const onBlur = (event: any) => {
    setIsEditing(false);
    if (event.target.value.trim() === '') {
      setEditingValue(step);
    } else {
      update(
        produce(method, (draft) => {
          // eslint-disable-next-line no-param-reassign
          draft.steps[index] = editingValue;
        })
      );
    }
    setIsEditing(false);
  };
  return (
    <div className="px-1 rounded-md flex gap-1 sm:gap-2 items-stretch text-xs sm:text-sm leading-5 font-medium text-opacity-70 w-full h-auto group/step">
      <div className="flex items-center justify-center w-6 h-8 bg-gray-50 sm:bg-white sm:group-hover/step:bg-gray-200 rounded-md">
        <DragStep className="w-5 h-5 self-center text-gray-900 sm:group-hover/step:text-gray-900" />
      </div>
      <div className="w-full h-full group-hover/step:bg-white rounded-md outline outline-1 group-hover/step:outline-gray-100 outline-white outline-offset-[-1px] mr-7">
        {isEditing ? (
          <textarea
            /* eslint-disable-next-line jsx-a11y/no-autofocus */
            autoFocus
            ref={inputRef}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            onFocus={moveCaretAtEnd}
            key={step}
            onKeyUp={textAreaAdjust}
            onClick={(e) => {
              return e.stopPropagation();
            }}
            className="w-full h-full resize-none px-1 py-2 focus:outline-none"
            value={editingValue}
          />
        ) : (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
          <p
            key={step}
            onClick={(event) => {
              event.stopPropagation();
              setIsEditing(true);
              if (inputRef.current) {
                // @ts-ignore
                inputRef.current.focus();
              }
            }}
            className="w-full px-2 py-1.5"
          >
            {`${index + 1}. ${editingValue}`}
          </p>
        )}
      </div>
      <div className="flex h-full items-start justify-center invisible group-hover/step:visible pr-1 relative">
        <button
          type="button"
          className="hidden w-8 h-8 justify-center items-center aspect-square rounded-md bg-deletered group-hover:flex hover:bg-opacity-70 transition-colors absolute right-0"
          onClick={(event) => {
            event.stopPropagation();
            setIsEditing(false);
            return update(
              produce(method, (draft) => {
                draft.steps.splice(index, 1);
              })
            );
          }}
        >
          <DeleteStep className="w-3.5 h-4" fill="white" fillOpacity="1" />
        </button>
      </div>
    </div>
  );
};
