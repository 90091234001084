import React from 'react';
import {Link} from 'react-router-dom';

import {RoutePath} from 'Router';
import {useDocumentTitle} from 'hooks';

export const ExampleListPage: React.FC = () => {
  useDocumentTitle('exampleList');
  return (
    <div>
      <h2>Example List Page</h2>
      <div>
        <Link to={`${RoutePath.list}/1`}>Detail 1</Link>
      </div>
      <div>
        <Link to={RoutePath.root}>Root</Link>
      </div>
    </div>
  );
};
