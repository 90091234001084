import React from 'react';
import {Droppable} from '@hello-pangea/dnd';
import {Dayjs} from 'dayjs';
import {ArrowsPointingInIcon} from '@heroicons/react/20/solid';
import {WorkshopCreatorDraggableTile} from './WorkshopCreatorDraggableTile';
import {editableMethodToDraggableId} from '../helpers';

interface WorkshopCreatorProps {
  editableMethods: ToolboxObject.EditableMethod[];
  setEditableMethods: (methods: ToolboxObject.EditableMethod[]) => void;
  startDate: Dayjs | null;
  droppableId: string;
}

const WorkshopCreatorComponent: React.FC<WorkshopCreatorProps> = ({editableMethods, setEditableMethods, droppableId, startDate}) => {
  return (
    <div className="p-6 sm:p-8 pr-6 border-2 border-dashed border-gray-200 rounded-xl bg-gray-50">
      <Droppable droppableId={droppableId} type="TILE">
        {(provided) => {
          return (
            <div className="flex flex-col h-full gap-4" {...provided.droppableProps} ref={provided.innerRef}>
              {editableMethods.length ? (
                editableMethods.map((editableMethod: ToolboxObject.EditableMethod, index) => {
                  const key = editableMethodToDraggableId(editableMethod, index);
                  return (
                    <WorkshopCreatorDraggableTile
                      key={key}
                      index={index}
                      draggableId={key}
                      startDate={startDate}
                      editableMethods={editableMethods}
                      setEditableMethods={setEditableMethods}
                    />
                  );
                })
              ) : (
                <div className="text-xl w-full h-full flex justify-center items-center gap-4 p-8">
                  <ArrowsPointingInIcon className="h-[1.5em] w-[1.5em]" color="#9CA3AF" />
                  <p className="font-bold text-gray-400">Drag and drop methods here</p>
                </div>
              )}
              {provided.placeholder}
            </div>
          );
        }}
      </Droppable>
    </div>
  );
};

export const WorkshopCreator = React.memo(WorkshopCreatorComponent);
