import {Button, Form, Input, Select} from 'antd';
import React, {useState} from 'react';
import {toast} from 'react-toastify';
import {Modal} from '../Modal';
import {useInvite} from '../../services/query';

interface InviteModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const InviteModal: React.FC<InviteModalProps> = ({isOpen, onClose}) => {
  const [form] = Form.useForm();
  const {mutateAsync: invite, isLoading} = useInvite();
  const onFinish = async (values: {email: string; firstName: string; lastName: string; role: string}) => {
    try {
      await invite(values);
      form.resetFields();
      toast.success(`User invited successfully`, {
        position: 'top-center',
        autoClose: 1000,
        theme: 'dark',
      });
      onClose();
    } catch (e) {
      toast.error(`Could not invite User`, {
        position: 'top-center',
        autoClose: 1000,
        theme: 'dark',
      });
    }
  };
  const [disabledInvite, setDisabledInvite] = useState(true);

  const handleFormChange = () => {
    setDisabledInvite(
      !form.isFieldsTouched(true) ||
        form
          .getFieldsError()
          .slice(0, 3)
          .filter(({errors}) => errors.length).length > 0
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="h-fit max-w-[500px] mx-auto">
      <p className="font-bold text-xl mb-8">Invite new User</p>
      <Form form={form} name="Invite new User" onFinish={onFinish} layout="vertical">
        <Form.Item
          name="email"
          label="E-mail"
          rules={[
            {
              type: 'email',
              message: 'The input is not valid E-mail',
            },
            {
              required: true,
              message: "Please input the user's E-mail",
            },
          ]}
        >
          <Input onChange={handleFormChange} />
        </Form.Item>
        <Form.Item
          name="firstName"
          label="First Name"
          tooltip="What is the user's first name?"
          rules={[{required: true, message: 'Please input a first name', whitespace: true}]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="lastName"
          label="Last Name"
          tooltip="What is the user's last name?"
          rules={[{required: true, message: 'Please input a last name', whitespace: true}]}
        >
          <Input onChange={handleFormChange} />
        </Form.Item>
        <Form.Item name="role" label="User Role">
          <Select
            defaultValue={{value: '', label: 'No Role'}}
            options={[
              {value: '', label: 'No Role'},
              {value: 'miro', label: 'Miro Access'},
              {value: 'admin', label: 'Administrator'},
            ]}
            onChange={handleFormChange}
          />
        </Form.Item>
        <Form.Item className="my-4">
          <Button type="primary" htmlType="submit" disabled={disabledInvite} loading={isLoading}>
            Send Invitation E-Mail
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
