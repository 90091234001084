import dayjs from 'dayjs';

export const methodIdToDraggableId = (methodId: number, droppableId: string, extra?: string): string => {
  return `${methodId.toString()}_${droppableId}_${extra ?? ''}`;
};

export const editableMethodToDraggableId = (editableMethod: ToolboxObject.EditableMethod, index: number): string => {
  return (
    editableMethod.original_method.id +
    editableMethod.title +
    editableMethod.teaser +
    editableMethod.description +
    (editableMethod.steps ? editableMethod.steps.join('-') : '') +
    index.toString()
  );
};

export const methodToEditableMethod = (method: ToolboxObject.Method, order: number): ToolboxObject.EditableMethod => {
  const {
    name,
    title,
    description,
    teaser,
    icon,
    duration,
    difficulty,
    subcategory,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    min_people,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    is_break,
  } = method;
  return {
    original_method: method,
    order,
    steps: [
      ...(method.steps.preparation?.map((p) => {
        return p.description;
      }) ?? []),
      ...(method.steps.execution?.map((e) => {
        return e.description;
      }) ?? []),
    ],
    name,
    title,
    description,
    teaser,
    icon,
    duration,
    difficulty,
    min_people,
    is_break,
    subcategory,
  };
};

const draggableIdToMethodId = (draggableId: string): number => {
  const split = draggableId.split('_');
  return +split[0];
};

export const draggableIdToEditableMethod = (methods: ToolboxObject.Method[], draggableId: string): ToolboxObject.EditableMethod => {
  const methodId = draggableIdToMethodId(draggableId);
  const methodIndex = methods.findIndex((m) => {
    return m.id === methodId;
  });
  if (methodIndex === -1) {
    throw new Error('Method not found');
  }
  return methodToEditableMethod(methods[methodIndex], methodIndex);
};

export const defaultStartDate = dayjs().hour(9).minute(0).toJSON();

export const getEmptySession: (props?: {
  order?: number;
  startDate?: dayjs.Dayjs | string;
  methods?: ToolboxObject.EditableMethod[];
  title?: string;
}) => ToolboxObject.WorkshopSession = (props) => {
  const {order = 0, startDate = undefined, methods = [], title = undefined} = props || {};
  const start = startDate || defaultStartDate;
  return {
    order,
    editable_methods: methods,
    start,
    title,
  };
};

export const getWorkshopState = (workshop: ToolboxObject.WorkshopEditableState): ToolboxObject.WorkshopEditableState => {
  return {
    title: workshop.title,
    sessions: workshop.sessions,
    description: workshop.description,
    requirements: workshop.requirements,
    benefits: workshop.benefits,
    category: workshop.category,
    teaser: workshop.teaser ?? null,
  };
};

export interface WorkshopModificationState {
  sessions: ToolboxObject.WorkshopSession[];

  setSessions(sessions: ToolboxObject.WorkshopSession[]): void;

  activeSession: number;

  setActiveSession(sessionIndex: number): void;
}
