import React from 'react';

import {Card} from 'components';
import {Puzzle, Fire} from 'assets';

import styles from './Description.module.scss';
import {upperFirst} from '../../../../services';

interface DescriptionProps {
  recipe: ToolboxObject.Workshop;
}

export const Description: React.FC<DescriptionProps> = ({recipe}) => {
  return (
    <div className={styles.description}>
      {recipe.description && <p className={styles.text}>{upperFirst(recipe.description ?? 'coming soon')}</p>}
      <div className={styles.wrapper}>
        {!!recipe.requirements?.length && (
          <Card headerIcon={<Puzzle />} headerTitle="Requirements" color="#E3E7EB">
            <ul>
              {recipe.requirements.map((requirement, index) => {
                // eslint-disable-next-line react/no-array-index-key
                return <li key={requirement + index}>{upperFirst(requirement)}</li>;
              })}
            </ul>
          </Card>
        )}
        {!!recipe.benefits?.length && (
          <Card headerIcon={<Fire />} headerTitle="Benefits" color="#E3E7EB">
            <ul>
              {recipe.benefits.map((benefit, index) => {
                // eslint-disable-next-line react/no-array-index-key
                return <li key={benefit + index}>{upperFirst(benefit)}</li>;
              })}
            </ul>
          </Card>
        )}
      </div>
    </div>
  );
};
