import React, {useMemo, useState} from 'react';

import styles from './Methods.module.scss';
import {RecipeMethodLibraryTile} from '../../../LibraryTile/RecipeMethodLibraryTile';
import {NavTabs} from '../../../../pages/LibraryPage/NavTabs';

interface MethodsProps {
  recipe: ToolboxObject.Workshop;
}

export const Methods: React.FC<MethodsProps> = ({recipe}) => {
  const tabs: string[] = useMemo(() => {
    return recipe.sessions
      .map((i, index) => {
        return `Day ${index + 1}`;
      })
      .sort();
  }, [recipe.sessions]);
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const filteredMethods = useMemo(() => {
    if (!tabs.length) {
      return [];
    }
    return recipe.sessions.filter((session) => {
      return activeTab === `Day ${(session.order ?? 0) + 1}`;
    })[0].editable_methods;
  }, [recipe.sessions, activeTab]);
  return (
    <div className={styles.wrapper}>
      {tabs.length > 1 && (
        <NavTabs
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          disableNavigate
          className="w-full border-b border-solid"
          titles={recipe.sessions.map((s) => s.title)}
        />
      )}
      <div className={styles.methods}>
        {filteredMethods.map((method) => {
          const key = `${activeTab}.${method.order}`;
          return <RecipeMethodLibraryTile method={method} key={key} modalId={key} />;
        })}
      </div>
    </div>
  );
};
