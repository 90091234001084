import React, {useMemo} from 'react';
import produce from 'immer';
import {Draggable, Droppable} from '@hello-pangea/dnd';
import {MethodDetailContainer} from 'components/MethodDetail/MethodDetailContainer';

import {v4 as uuidv4} from 'uuid';
import {Modal} from '../../Modal';
import {ExpandArrow} from '../../../assets';
import {EditableMethodStep} from './EditableMethodStep';
import {EditableMethodFooter} from './EditableMethodFooter';
import {useModal} from '../../../hooks';
import {EditableMethodTitle} from './EditableMethodTitle';
import {EditableMethodDescription} from './EditableMethodDescription';

interface EditableMethodLibraryTileProps {
  method: ToolboxObject.EditableMethod;
  update: any;
  tileIndex: number;
  durationRef: React.MutableRefObject<HTMLDivElement | undefined> | undefined;
  nested?: boolean;
}

export const EditableMethodLibraryTile: React.FC<EditableMethodLibraryTileProps> = ({method, nested = false, update, durationRef, tileIndex}) => {
  const {isOpen, close, open} = useModal('method', method.original_method.id.toString(), true);
  const indexedSteps = useMemo(() => {
    if (!method.steps) {
      return [];
    }
    return method.steps.map((step) => {
      return {key: uuidv4(), value: step};
    });
  }, [method.steps]);
  const onCard = () => {
    if (method.is_break) {
      return;
    }
    open();
  };
  return (
    <>
      <Modal onClose={close} isOpen={isOpen} nested={nested}>
        <MethodDetailContainer id={method.original_method.id} />
      </Modal>
      <div className="group w-full">
        <div
          className="flex rounded-xl border-solid border border-black shadow-hard bg-white
          group-hover:shadow-pop group-hover:mt-[-2px] group-hover:mb-[2px]
          transition-all font-['Inter'] w-full h-full break-words overflow-visible"
        >
          {method.original_method.iconLink && (
            <div style={{backgroundColor: method.subcategory?.category.color}} className="flex-none rounded-l-xl cursor-pointer" onClick={onCard} aria-hidden>
              <img src={method.original_method.iconLink} alt={method.original_method.icon} width={65} />
            </div>
          )}
          <div className="flex flex-col rounded-r-xl gap-3 px-4 py-3 w-full justify-between">
            <div className="flex flex-col gap-2">
              <div className="flex justify-between">
                <EditableMethodTitle method={method} title={method.title} update={update} />
                <button
                  type="button"
                  className="focus:outline-none w-6 aspect-square hover:bg-gray-200 flex justify-center items-center rounded-md"
                  onClick={(event) => {
                    event.stopPropagation();
                    return update(
                      produce(method, (draft) => {
                        // eslint-disable-next-line no-param-reassign
                        draft.expanded = !method.expanded;
                      })
                    );
                  }}
                >
                  <ExpandArrow className={method.expanded ? 'rotate-270' : 'rotate-90'} />
                </button>
              </div>
              <EditableMethodDescription method={method} description={method.teaser ?? null} update={update} />
            </div>
            {method.expanded && (
              <div className="flex flex-col">
                <Droppable droppableId={`step-list-${tileIndex}`} type={`STEPS-${tileIndex}`}>
                  {(provided) => {
                    return (
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      <div ref={provided.innerRef} {...provided.droppableProps} style={{overflow: 'hidden'}}>
                        {indexedSteps.map((step, index) => {
                          return (
                            <Draggable draggableId={step.key} index={index} key={step.key}>
                              {(providedDraggable) => {
                                return (
                                  <div
                                    ref={providedDraggable.innerRef}
                                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                                    {...providedDraggable.draggableProps}
                                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                                    {...providedDraggable.dragHandleProps}
                                    style={{...providedDraggable.draggableProps.style, position: 'static'}}
                                  >
                                    <EditableMethodStep method={method} step={step.value} index={index} update={update} />
                                  </div>
                                );
                              }}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    );
                  }}
                </Droppable>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div
                  onClick={(event) => {
                    event.stopPropagation();
                    return update(
                      produce(method, (draft) => {
                        // eslint-disable-next-line no-param-reassign
                        draft.steps.push('New Step');
                      })
                    );
                  }}
                >
                  <p className="cursor-pointer box-border text-xs sm:text-sm leading-5 font-medium w-auto text-gray-500 px-3 py-2 ml-[34px] hover:bg-white rounded-md border hover:border-gray-100 border-white mr-10">
                    + Add Step
                  </p>
                </div>
              </div>
            )}
            <EditableMethodFooter method={method} update={update} durationRef={durationRef} />
          </div>
        </div>
      </div>
    </>
  );
};
