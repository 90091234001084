import React, {PropsWithChildren} from 'react';
import {createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider, useRouteError} from 'react-router-dom';

import {
  AdminDashboardPage,
  AdminMethodPage,
  ErrorPage,
  ExampleDetailPage,
  ExampleListPage,
  ForgotPasswordPage,
  HowToPage,
  LibraryPage,
  LoginPage,
  RegisterPage,
  ResetPasswordPage,
  RootPage,
  WorkshopLibraryPage,
} from 'pages';
import {errorHandler} from 'services';
import {useStateLocation} from 'hooks';
import {adminLibraryTabs, libraryTabs} from 'constants/library.constants';
import {NewWorkshopPage, WorkshopPage} from 'pages/WorkshopsPage';
import {PageLayout} from 'layout';
import {UserRole} from 'types';
import {useMethods, useRecipes, useSelf, useWorkshops} from './services/query';

export enum RoutePath {
  root = '/',
  error = '/error',
  list = '/list',
  library = '/library',
  workshopLibrary = '/workshops',
  workshopEditor = '/editor',
  login = '/login',
  register = '/register',
  howto = '/howto',
  resetPassword = '/reset/password',
  forgotPassword = '/forgot/password',
  adminDashboard = '/admin-dashboard',
}

const ErrorBoundary = () => {
  const location = useStateLocation();

  const error = useRouteError();
  const extendedError = errorHandler.handle(error);

  return location.pathname === RoutePath.root ? <div>{extendedError.message}</div> : <Navigate to="/" replace />;
};

const ProtectedRoute: React.FC<any> = ({children}) => {
  // check data is being fetched
  useMethods();
  useRecipes();
  useWorkshops();
  useSelf();
  if (!localStorage.getItem('userSession')) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

const AdminRoute: React.FC<PropsWithChildren> = ({children}) => {
  const {data: user, isLoading} = useSelf();
  if (!isLoading && user?.role !== UserRole.ADMIN) {
    return <Navigate to={RoutePath.root} replace />;
  }
  return <ProtectedRoute>{children}</ProtectedRoute>;
};

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route ErrorBoundary={ErrorBoundary} element={<PageLayout />}>
        <Route
          path={RoutePath.root}
          element={
            <ProtectedRoute>
              <RootPage />
            </ProtectedRoute>
          }
        />
        <Route path={RoutePath.library}>
          <Route index element={<Navigate to={`${RoutePath.library}/recipes`} />} />
          {libraryTabs.map((tab) => (
            <Route
              key={`${RoutePath.library}/${tab}`}
              path={tab}
              element={
                <ProtectedRoute>
                  <LibraryPage defaultTab={tab} />
                </ProtectedRoute>
              }
            />
          ))}
        </Route>
        <Route path={RoutePath.workshopEditor}>
          <Route index element={<Navigate to={`${RoutePath.workshopEditor}/new`} />} />
          <Route path=":workshopId" element={<WorkshopPage />} />
          <Route path="new" element={<NewWorkshopPage />} />
          <Route
            path="/editor/recipe/:workshopId"
            element={
              <AdminRoute>
                <WorkshopPage recipe />
              </AdminRoute>
            }
          />
        </Route>
        <Route
          path={RoutePath.workshopEditor}
          element={
            <ProtectedRoute>
              <NewWorkshopPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={RoutePath.workshopLibrary}
          element={
            <ProtectedRoute>
              <WorkshopLibraryPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={RoutePath.howto}
          element={
            <ProtectedRoute>
              <HowToPage />
            </ProtectedRoute>
          }
        />

        <Route path={RoutePath.adminDashboard}>
          <Route index element={<Navigate to={`${RoutePath.adminDashboard}/methods`} />} />
          {adminLibraryTabs.map((tab) => (
            <Route
              key={`${RoutePath.adminDashboard}/${tab}`}
              path={tab}
              element={
                <AdminRoute>
                  <AdminDashboardPage defaultTab={tab} />
                </AdminRoute>
              }
            />
          ))}
          <Route
            path={`${RoutePath.adminDashboard}/methods/:id`}
            element={
              <AdminRoute>
                <AdminMethodPage />
              </AdminRoute>
            }
          />
        </Route>
        <Route path={RoutePath.error} element={<ErrorPage />} />
        <Route path={RoutePath.list}>
          <Route index element={<ExampleListPage />} />
          <Route path=":id" element={<ExampleDetailPage />} />
        </Route>
        <Route path="*" element={<Navigate to={RoutePath.root} />} />
      </Route>
      <Route path={RoutePath.register} element={<RegisterPage />} />
      <Route path={RoutePath.resetPassword} element={<ResetPasswordPage />} />
      <Route path={RoutePath.login} element={<LoginPage />} />
      <Route path={RoutePath.forgotPassword} element={<ForgotPasswordPage />} />
    </>
  )
);

export const Router: React.FC = () => {
  return <RouterProvider router={router} />;
};
