import React, {useState} from 'react';
import {MethodDetailContainer} from 'components/MethodDetail/MethodDetailContainer';
import {Modal} from '../../Modal';
import {Description, Footer, Title} from '../components';
import {useViewMethod} from '../../../services/query';
import {ExpandArrow} from '../../../assets';
import {useModal} from '../../../hooks';

interface RecipeMethodLibraryTileProps {
  method: ToolboxObject.EditableMethod;
  modalId: string;
}

export const RecipeMethodLibraryTile: React.FC<RecipeMethodLibraryTileProps> = ({method, modalId}) => {
  const {isOpen, close, open} = useModal('recipe_method', modalId);
  const [expanded, setExpanded] = useState(false);
  const {mutate: view} = useViewMethod(method.original_method.id);

  const onCard = () => {
    if (method.is_break) {
      return;
    }
    open();
    view();
  };

  return (
    <>
      <Modal onClose={close} isOpen={isOpen} nested>
        <MethodDetailContainer id={method.original_method.id} />
      </Modal>
      <div className="group w-full">
        <div
          className="flex rounded-xl border-solid border border-black shadow-hard bg-white
    group-hover:shadow-pop group-hover:mt-[-2px] group-hover:mb-[2px] cursor-pointer
    transition-all font-['Inter'] w-full h-full break-words overflow-visible"
          onClick={onCard}
          aria-hidden
        >
          <div style={{backgroundColor: method.subcategory?.category.color}} className="flex-none rounded-l-xl">
            <img src={method.original_method.iconLink} alt={method.original_method.icon} />
          </div>
          <div className="flex flex-col rounded-r-xl gap-3 px-4 py-3 w-full justify-between">
            <div className="flex flex-col gap-2">
              <div className="flex justify-between">
                <Title text={method.title} />
                {!!method.steps?.length && (
                  <button
                    type="button"
                    className="focus:outline-none w-6 aspect-square hover:bg-gray-200 flex justify-center items-center rounded-md"
                    onClick={(event) => {
                      event.stopPropagation();
                      setExpanded(!expanded);
                    }}
                  >
                    <ExpandArrow className={expanded ? 'rotate-270' : 'rotate-90'} />
                  </button>
                )}
              </div>
              {method.teaser ? <Description text={method.teaser} /> : method.description && <Description text={method.description} />}
              {expanded && (
                <div className="flex flex-col gap-3 my-4 px-4">
                  {method.steps.map((step, index) => {
                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <p key={index} className="w-full text-xs sm:text-sm leading-5 font-medium text-opacity-70">
                        {`${index + 1}. ${step}`}
                      </p>
                    );
                  })}
                </div>
              )}
              <Footer duration={method.duration} text={method.subcategory?.name} hideExtras />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
