import React, {useEffect, useRef, useState} from 'react';
import produce from 'immer';
import {DeleteStep, DragStep} from '../../../assets';

interface WorkshopInfoItemProps {
  infos: string[];
  info: string;
  type: string;
  focusInfo: boolean;
  index: number;
  update: any;
}

export const WorkshopInfoItem: React.FC<WorkshopInfoItemProps> = ({infos, info, index, update, type, focusInfo}) => {
  const [editingValue, setEditingValue] = useState(info);
  const inputRef = useRef(null);

  useEffect(() => {
    // @ts-ignore
    inputRef.current.style.height = '1px';
    // @ts-ignore
    inputRef.current.style.height = `${1 + inputRef.current.scrollHeight}px`;
  }, [editingValue, inputRef]);

  const onChange = (event: any) => {
    return setEditingValue(event.target.value);
  };
  const onKeyDown = (event: any) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.target.blur();
    } else if (event.key === 'Escape') {
      setEditingValue(info);
      event.target.blur();
    }
  };
  const onBlur = (event: any) => {
    if (event.target.value.trim() === '') {
      setEditingValue(info);
    } else {
      update(
        produce(infos, (draft) => {
          // eslint-disable-next-line no-param-reassign
          draft[index] = editingValue;
        })
      );
    }
  };
  return (
    <div className="px-1 rounded-md flex gap-1 sm:gap-2 items-stretch text-xs sm:text-sm leading-5 font-medium text-opacity-70 w-full h-auto group/info">
      <div
        style={{display: editingValue !== info ? 'none' : undefined}}
        className="flex items-center justify-center w-6 h-8 bg-gray-50 sm:bg-white sm:group-hover/info:bg-gray-200 rounded-md"
      >
        <DragStep className="w-5 h-5 self-center text-gray-900 sm:group-hover/info:text-gray-900" />
      </div>
      <div className="w-full h-8 group-hover/info:bg-white rounded-md outline outline-1 group-hover/info:outline-gray-100 outline-white outline-offset-[-1px]  mr-7">
        <textarea
          /* eslint-disable-next-line jsx-a11y/no-autofocus */
          autoFocus={focusInfo && !info}
          ref={inputRef}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          key={info + index}
          className="w-full resize-none px-2 py-1.5"
          value={editingValue}
          placeholder={`New ${type}`}
        />
      </div>
      <div className="flex h-full items-start justify-center invisible group-hover/info:visible pr-1 relative">
        <button
          type="button"
          className="w-8 h-8 justify-center items-center aspect-square rounded-md bg-deletered group-hover:flex hover:bg-opacity-70 transition-colors absolute right-0"
          onClick={(event) => {
            event.stopPropagation();
            return update(
              produce(infos, (draft) => {
                draft.splice(index, 1);
              })
            );
          }}
        >
          <DeleteStep className="w-3.5 h-4 mx-auto" fill="white" fillOpacity="1" />
        </button>
      </div>
    </div>
  );
};
