import React from 'react';

interface TitleProps {
  text: string;
  color?: string | undefined;
}

export const Title: React.FC<TitleProps> = ({text, color = undefined}) => {
  return (
    <p className="w-full mr-2 text-base leading-5 font-bold text-black break-words" style={{color}}>
      {text}
    </p>
  );
};
