export const groupMethodsByCategory = (methods: ToolboxObject.Method[], showOthers: boolean) => {
  return methods.reduce((hash: {[index: string]: any}, method) => {
    if (showOthers && method.is_break) {
      return Object.assign(hash, {'Other Activities': (hash['Other Activities'] || []).concat(method)});
    }
    if (!method.subcategory || !method.subcategory.category || !method.subcategory.category.name) {
      return hash;
    }
    return Object.assign(hash, {[method.subcategory.category.name]: (hash[method.subcategory.category.name] || []).concat(method)});
  }, {});
};

const methodCategoryOrder: string[] = ['Anfangsphase', 'Arbeitsphase', 'Abschlussphase', 'Other Activities'];
export const sortMethodCategories = (a: [string, ToolboxObject.Method], b: [string, ToolboxObject.Method]) => {
  const i = methodCategoryOrder.findIndex((c) => {
    return c === a[0];
  });
  const j = methodCategoryOrder.findIndex((c) => {
    return c === b[0];
  });
  return i - j;
};

const recipeCategoryOrder: string[] = ['top recipes', 'additional recipes and variants', 'others'];
export const sortRecipeCategories = (a: [string, ToolboxObject.Workshop], b: [string, ToolboxObject.Workshop]) => {
  const i = recipeCategoryOrder.findIndex((c) => {
    return c === a[0].toLowerCase();
  });
  const j = recipeCategoryOrder.findIndex((c) => {
    return c === b[0].toLowerCase();
  });
  return i - j;
};

export const groupRecipesByCategory = (recipes: ToolboxObject.Workshop[]) => {
  return recipes.reduce((hash: {[index: string]: any}, recipe) => {
    if (!recipe.category || !recipe.category.name) return Object.assign(hash, {others: (hash.others || []).concat(recipe)});
    return Object.assign(hash, {[recipe.category.name]: (hash[recipe.category.name] || []).concat(recipe)});
  }, {});
};

export const upperFirst = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const EMAIL_REGEX: RegExp =
  /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;
