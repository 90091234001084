import React from 'react';

import {LightBulb} from 'assets';
import {Card} from 'components';
import {upperFirst} from '../../../../services';

interface TipsAndMaterialProps {
  method: ToolboxObject.MethodDetails;
}

export const Tips: React.FC<TipsAndMaterialProps> = ({method}) => {
  if (method?.infos?.tip) {
    return (
      <div className="mt-[90px]">
        <Card headerIcon={<LightBulb />} headerTitle="Tips" color={method.subcategory?.category.color}>
          <ul>
            {method.infos.tip.map((info) => {
              return <li key={info.id}>{upperFirst(info.description)}</li>;
            })}
          </ul>
        </Card>
      </div>
    );
  }
  return null;
};
