import React from 'react';

import styles from './Card.module.scss';

interface CardProps {
  children: React.ReactNode;
  headerIcon: React.ReactNode;
  headerTitle: string;
  color?: string;
}

export const Card: React.FC<CardProps> = ({children, headerIcon, headerTitle, color = 'white'}) => {
  return (
    <div className={styles.methodCard}>
      <header className={styles.header} style={{backgroundColor: color}}>
        {headerIcon}
        <h2>{headerTitle}</h2>
      </header>
      <div>{children}</div>
    </div>
  );
};
