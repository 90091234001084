import React from 'react';

export const CompanyLogoLong = ({...props}) => {
  const {iswhite} = props;
  return (
    <div className={props.className} style={{marginLeft: '-130px', marginBottom: '12px'}}>
      <svg
        version="1.1"
        id="Ebene_1"
        xmlns="http://www.w3.org/2000/svg"
        // xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="169.939px"
        height="39.846px"
        viewBox="0 0 169.939 39.846"
        enableBackground="new 0 0 169.939 39.846"
        // xml:space="preserve"
      >
        <g>
          <g>
            <g>
              <g>
                <polygon fill="#563B6C" points="147.832,27.238 140.111,29.221 146.111,15.355 153.86,13.507 				" />
              </g>
            </g>
            <g>
              <polygon fill="#71518C" points="145.726,1.699 153.506,0 156.638,7.181 148.879,8.967 			" />
            </g>
            <g>
              <polygon fill="#8761A8" points="134.379,28.239 145.726,1.699 148.879,8.967 140.111,29.221 			" />
            </g>
          </g>
          <g>
            <polygon fill="#118289" points="140.111,29.221 147.832,27.238 160.77,29.398 153.069,31.438 		" />
            <g>
              <polygon fill="#46A8B3" points="156.236,38.762 131.764,34.366 134.379,28.239 153.069,31.438 			" />
            </g>
          </g>
          <g>
            <g>
              <polygon fill="#9F1717" points="148.879,8.967 156.638,7.181 169.939,37.689 162.277,39.846 			" />
            </g>
            <g>
              <polygon fill="#C21924" points="146.111,15.355 148.879,8.967 162.277,39.846 156.236,38.762 			" />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};
