import React, {useState} from 'react';

interface WorkshopDescriptionProps {
  initialValue: string;
  update: (text: string) => void;
}

export const WorkshopDescription: React.FC<WorkshopDescriptionProps> = ({initialValue, update}) => {
  const [editingValue, setEditingValue] = useState(initialValue);
  const onChange = (event: any) => {
    return setEditingValue(event.target.value);
  };
  const onKeyDown = (event: any) => {
    if (event.key === 'Escape') {
      setEditingValue(initialValue);
      event.target.blur();
    }
  };
  const onBlur = () => {
    update(editingValue);
  };
  return (
    <textarea
      onChange={onChange}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      onClick={(e) => {
        return e.stopPropagation();
      }}
      className="p-1 resize-y w-full h-full min-h-[144px] text-xs sm:text-sm leading-5 font-medium text-black rounded-md border border-black overflow-y-auto"
      value={editingValue ?? ''}
    />
  );
};
