import React from 'react';
import {Dayjs} from 'dayjs';
import {DatePicker, TimePicker} from 'antd';
import {humanizeMillis} from '../../../services/utils/date';
import {WorkshopFormResult} from '../hooks/useWorkshopForm';

interface WorkshopFormItemProps {
  label: string;
  children: React.ReactNode;
}

const WorkshopFormItem: React.FC<WorkshopFormItemProps> = ({label, children}) => {
  return (
    <div className="flex gap-1 items-center justify-start text-sm font-medium w-full sm:w-auto">
      <p className="flex-shrink-0 font-bold">{`${label}:`}</p>
      {children}
    </div>
  );
};

interface WorkshopFormProps extends WorkshopFormResult {
  editableMethods: ToolboxObject.EditableMethod[] | null;
  startDate: Dayjs | null;
}

const WorkshopFormComponent: React.FC<WorkshopFormProps> = ({editableMethods, startDate, setStartTime, setDate}) => {
  const timeFormat = 'HH:mm';
  return (
    <div className="flex flex-wrap gap-4">
      <WorkshopFormItem label="Date">
        <DatePicker className="w-full" value={startDate} onChange={setDate} />
      </WorkshopFormItem>
      <WorkshopFormItem label="Start Time">
        <TimePicker className="w-full" format={timeFormat} value={startDate} onChange={setStartTime} />
      </WorkshopFormItem>
      {editableMethods != null && !!editableMethods.length && (
        <>
          <WorkshopFormItem label="Duration">
            <p>
              {humanizeMillis(
                editableMethods.reduce((accumulator, method) => {
                  return accumulator + (method.duration ?? 0);
                }, 0)
              )}
            </p>
          </WorkshopFormItem>
          <WorkshopFormItem label="Methods">
            <p>{editableMethods.length}</p>
          </WorkshopFormItem>
        </>
      )}
    </div>
  );
};

export const WorkshopForm = React.memo(WorkshopFormComponent);
