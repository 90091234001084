import React from 'react';
import {Link, useParams} from 'react-router-dom';

import {RoutePath} from 'Router';
import {useDocumentTitle} from 'hooks';

export const ExampleDetailPage: React.FC = () => {
  const {id = ''} = useParams();
  useDocumentTitle('exampleDetail');

  return (
    <div>
      <h2>Example Detail Page (id={id})</h2>
      <div>
        <Link to={RoutePath.list}>List</Link>
      </div>
      <div>
        <Link to={RoutePath.root}>Root</Link>
      </div>
    </div>
  );
};
