import React, {useEffect, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {FreeMode, Mousewheel, Pagination, Scrollbar} from 'swiper';
import {DescriptionCards, Header, MethodImageCarousel, Resources, SideBar, Steps, Tips} from './components';

import styles from './MethodDetail.module.scss';
import 'swiper/scss';
import 'swiper/scss/free-mode';
import 'swiper/scss/scrollbar';
import 'swiper/scss/pagination';
import {upperFirst} from '../../services';

interface MethodDetailProps {
  method: ToolboxObject.MethodDetails;
}

enum Anchors {
  'Description',
  'Steps',
  'Tips',
  'Resources',
}

export const MethodDetail: React.FC<MethodDetailProps> = ({method}) => {
  const [isRender, setIsRender] = useState(false);
  const pagination = {
    el: '.swiper-pagination',
    clickable: true,
    renderBullet: (index: number) => {
      return `<span class="swiper-pagination-bullet">${Anchors[index]}</span>`;
    },
  };

  useEffect(() => {
    setIsRender(true);
  }, []);

  return (
    <section className={styles.method}>
      <SideBar color={method.subcategory?.category.color} iconLink={method.iconLink} icon={method.icon}>
        {isRender && <div className="swiper-pagination" />}
      </SideBar>
      <div className="h-full w-full pb-4">
        <Header method={method} />
        <Swiper
          noSwiping={false}
          pagination={pagination}
          direction="vertical"
          slidesPerView="auto"
          freeMode
          mousewheel
          modules={[FreeMode, Scrollbar, Mousewheel, Pagination]}
          className="swiper-no-swiping"
        >
          <SwiperSlide>
            <div className={styles.description}>
              <div className={styles.banner}>
                <MethodImageCarousel files={method.files?.description} />
              </div>
              <div className={styles.text}>
                <p>{upperFirst(method.description ?? 'coming soon')}</p>
              </div>
            </div>
            <DescriptionCards method={method} />
          </SwiperSlide>
          <SwiperSlide>
            <Steps method={method} />
          </SwiperSlide>
          <SwiperSlide>
            <Tips method={method} />
          </SwiperSlide>
          <SwiperSlide>
            <Resources method={method} />
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
};
