import React from 'react';
import {Download, mockPreview} from '../../assets';

interface HowToDocumentProps {
  title: string;
  description: string;
}

export const HowToDocument: React.FC<HowToDocumentProps> = ({title, description}) => {
  return (
    <div className="flex flex-col max-w-[250px] whitespace-break-spaces overflow-hidden gap-3">
      <p className="text-xl leading-6 font-bold">{title}</p>
      <p className="text-sm leading-4 font-light">{description}</p>
      <img className="w-[113px] h-[160px] mt-2" src={mockPreview} alt="File preview" />
      <a
        className="inline-flex gap-1 px-4 py-2 border-black border border-solid rounded-md w-fit h-fit items-center justify-center bg-white hover:bg-gray-100"
        href={`${process.env.PUBLIC_URL}/files/test.pptx`}
        download="test.pptx"
        target="_blank"
        rel="noreferrer"
      >
        <Download /> Download
      </a>
    </div>
  );
};
