import produce from 'immer';
import {useCallback} from 'react';
import dayjs from 'dayjs';
import {DragStart, DropResult, OnDragEndResponder, OnDragStartResponder} from '@hello-pangea/dnd';
import {WorkshopModificationState, getEmptySession} from '../helpers';

const droppableId = 'day';

export interface SessionTabsResult {
  activeSession: number;
  sessions: ToolboxObject.WorkshopSession[];
  droppableId: string;

  setActiveSession(tabIndex: number): void;
  handleRemoveSession(tabIndex: number): void;
  handleRenameSession(tabIndex: number, newTitle: string): void;
  handleAddSession(copyMethods?: boolean): void;
  onDragEnd: OnDragEndResponder;
  onDragStart: OnDragStartResponder;
}

export const useSessionTabs = (
  props: WorkshopModificationState
): {
  handleAddSession: (copyMethods?: boolean) => void;
  handleRenameSession: (tabIndex: number, newTitle: string | undefined) => void;
  sessions: ToolboxObject.WorkshopSession[];
  activeSession: number;
  handleRemoveSession: (tabIndex: number) => void;
  setActiveSession: (sessionIndex: number) => void;
  droppableId: string;
  onDragEnd: (result: DropResult) => void;
  onDragStart: (start: DragStart) => void;
} => {
  const {sessions, setSessions, activeSession, setActiveSession} = props;
  const handleAddSession = useCallback(
    (copyMethods?: boolean) => {
      const prevStartDate = dayjs(sessions.at(-1)?.start);
      const session = sessions[activeSession];
      const startDate = copyMethods ? session.start : dayjs(prevStartDate).add(1, 'day').hour(9).minute(0).toJSON();
      const methods = copyMethods ? session.editable_methods : [];
      const title = copyMethods ? session.title : undefined;
      const newSessions = [...sessions, getEmptySession({order: sessions.length, methods, startDate, title})];

      setSessions(newSessions);
      setActiveSession(newSessions.length - 1);
    },
    [sessions, activeSession]
  );

  const handleRemoveSession = useCallback(
    (tabIndex: number) => {
      if (tabIndex === sessions.length - 1) {
        setActiveSession(activeSession - 1);
      }
      return setSessions(
        produce(sessions, (draft) => {
          draft.splice(tabIndex, 1);
        })
      );
    },
    [sessions, activeSession]
  );

  const handleRenameSession = useCallback(
    (tabIndex: number, newTitle: string | undefined) => {
      if (tabIndex === sessions.length - 1) {
        setActiveSession(activeSession - 1);
      }
      return setSessions(
        produce(sessions, (draft) => {
          draft[tabIndex].title = newTitle;
        })
      );
    },
    [sessions, activeSession]
  );

  const onDragEnd = useCallback(
    (result: DropResult) => {
      const {destination, source} = result;
      if (!destination || source.index === destination.index) {
        return;
      }

      setSessions(
        produce(sessions, (draft) => {
          const session = draft.splice(source.index, 1)[0];
          draft.splice(destination.index, 0, session);
          draft.forEach((item, i) => {
            item.order = i;
          });
        })
      );
      setActiveSession(destination.index);
    },
    [sessions]
  );
  const onDragStart = useCallback((start: DragStart) => {
    setActiveSession(start.source.index);
  }, []);

  return {
    activeSession,
    setActiveSession,
    handleAddSession,
    handleRemoveSession,
    handleRenameSession,
    sessions,
    onDragEnd,
    droppableId,
    onDragStart,
  };
};
